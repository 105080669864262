<template>
  <div>
    <div class="uebersicht container">
      <div>
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <essensplan></essensplan>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fhemmixin from '../FhemConnection';
import CardEssensplan from '../components/CardEssensplan.vue';

export default {
  components: {
    essensplan: CardEssensplan,
  },
  data() {
    return {};
  },
  mixins: [fhemmixin],
};
</script>
