module.exports = {
  chart:
  {
    type: 'line',
    options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'nearest',
        intersect: false,
      },
      title: {
        display: false,
        text: 'BTC-Kurs',
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            // unit: 'hour',
            displayFormats: {
              hour: 'HH:mm',
              day: 'D.M.',
            },
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Zeit',
          },
        }],
        yAxes: [{
          id: 'A',
          position: 'left',
          scalePositionLeft: true,
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Kurs',
          },
          gridLines:
        {
          color: '#ffbbbb',
        },
        },
        ],
      },
    },
    data: {
      datasets: [{
        label: 'BTC-Kurs',
        data: [],
        radius: 0,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132)',
        fill: false,
        yAxisID: 'A',
        fhemDevice: 'BTC',
        fhemReading: 'BTCNUM',
      },
      ],
    },
  },
};
