<template>
  <div class="widget">
    <div>
      <h2>
        {{ camname }}
        <font-awesome-icon v-if="!isLive" @click="play" icon="play" class="playpause" />
        <font-awesome-icon v-if="isLive" @click="pause" icon="pause" class="playpause" />
      </h2>
      <table class="table table-sm">
        <div>
          <div>
            <img ref="kamerabild" v-bind:src="urlPreview" class="img-fluid" />
          </div>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
import fhemmixin from '../FhemConnection';

export default {
  name: 'CardCameraAM',
  props: { camname: String, id: Number, startLive: Boolean },
  mixins: [fhemmixin],
  data() {
    return {
      isLive: false,
      urlPreview: `https://sbo9.de/haustuer.php?id=${
        this.id
      }&time=${new Date().getTime()}`,
    };
  },
  components: {},
  methods: {
    play() {
      this.isLive = true;
      this.urlPreview = `https://sbo9.de/haustuer.php?id=${
        this.id
      }&format=jpeg&time=${new Date().getTime()}`;

      this.intervalid1 = setInterval(() => {
        this.urlPreview = `https://sbo9.de/haustuer.php?id=${
          this.id
        }&format=jpeg&time=${new Date().getTime()}`;
      }, 2000);
    },
    pause() {
      clearInterval(this.intervalid1);
      this.isLive = false;
    },
  },
  mounted() {
    this.isLive = false;
    if (this.startLive) {
      this.play();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.playpause {
  float: right;
}

.img-fluid {
  width: 100%;
}
</style>
