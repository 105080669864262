import axios from 'axios';

const config = require('../config');

const url = config.BACKEND_URL;

export default {
  login(credentials) {
    return axios
      .post(`${url}api/auth/signin/`, credentials)
      .then((response) => response.data);
  },
  signUp(credentials) {
    return axios
      .post(`${url}api/auth/signup/`, credentials)
      .then((response) => response.data);
  },
};
