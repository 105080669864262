<template>
  <div>
    <div class="uebersicht container">
      <div>
        <div class="row" v-if="hasMuehlengraben">
          <div class="col-md-4 col-xs-12" v-if="hasWohnungBasti">
            <card v-bind:device="devicedata.heizung_am_zirk"></card>
          </div>
          <div class="col-md-4 col-xs-12" v-if="isBasti">
            <card v-bind:device="devicedata.heizung_am_pelletstatus"></card>
          </div>
          <div class="col-md-4 col-xs-12" v-if="isBasti">
            <card v-bind:device="devicedata.hk_status"></card>
          </div>
        </div>
        <div class="row" v-if="hasMuehlengraben">
          <div class="col-md-4 col-xs-12">
            <card v-bind:device="devicedata.heizung_am_kessel"></card>
          </div>
          <div class="col-md-4 col-xs-12">
            <card v-bind:device="devicedata.heizung_am_solar"></card>
          </div>
          <div class="col-md-4 col-xs-12">
            <card v-bind:device="devicedata.heizung_am_stat"></card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-xs-12" v-if="hasWasserweg">
            <cardheizung v-bind:device="devicedata.heizung"></cardheizung>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fhemmixin from '../FhemConnection';
import CardHeizung from '../components/CardHeizung.vue';

export default {
  components: {
    cardheizung: CardHeizung,
  },
  data() {
    return {};
  },
  mixins: [fhemmixin],
  methods: {},
  computed: {
    // a computed getter
  },
};
</script>
