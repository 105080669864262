<template>
  <div>
    <div class="uebersicht container">
      <div>
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.wohnung"></card>
            <card v-bind:device="devicedata.staubsauger"></card>
          </div>
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.wohnzimmer"></card>
          </div>
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.wohnung_mitte"></card>
            <card v-bind:device="devicedata.schlafzimmer"></card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.schlafzimmerBasti"></card>
          </div>
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.schlafzimmerEla"></card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.bad"></card>
          </div>
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.kueche"></card>
          </div>
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.buero"></card>
          </div>
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.bran"></card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from '../components/Card.vue';
import fhemmixin from '../FhemConnection';

export default {
  components: {
    card: Card,
  },
  data() {
    return {};
  },
  created() {
    if (!this.$store.getters.getUser.roles.includes('ROLE_HOME')) {
      this.$router.push('/');
    }
  },
  mixins: [fhemmixin],
  mounted() {},
  methods: {},
};
</script>
