<template>
  <div>
    <div class="uebersicht container">
      <div>
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <h1>Tür wird geöffnet...</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fhemmixin from '../FhemConnection';

export default {
  components: {},
  data() {
    return {};
  },
  mixins: [fhemmixin],
  async mounted() {
    // when the Vue app is booted up, this is run automatically.
    if (this.$store.getters.isLoggedIn) {
      this.$http.get('/api/opendooram').then(() => {
        this.$router.push('/ok');
      });
    }
  },
  methods: {},
  computed: {
    // a computed getter
  },
};
</script>
