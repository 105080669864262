<template>
  <section id="cover" class="min-vh-100">
    <div class="container form">
      <form v-on:submit.prevent class="justify-content-center form">
        <h3 class="dark">Login</h3>
        <p v-if="msg">{{ msg }}</p>

        <div class="form-group">
          <input type="text" placeholder="Username" v-model="username" />
        </div>

        <div class="form-group">
          <input type="password" placeholder="Passwort" v-model="password" />
        </div>

        <button @click="login" class="btn btn-dark btn-lg">Anmelden</button>
      </form>
    </div>
  </section>
</template>

<script>
import AuthService from '@/services/AuthService';
import { FhemConnectSocket } from '../FhemConnection';

export default {
  data() {
    return {
      username: '',
      password: '',
      msg: '',
    };
  },
  methods: {
    async login() {
      try {
        const credentials = {
          username: this.username.trim(),
          password: this.password.trim(),
        };
        const response = await AuthService.login(credentials);
        const { accessToken } = response;
        const { user } = response;
        this.$store.dispatch('login', { accessToken, user });
        this.$http.defaults.headers.common['x-access-token'] = accessToken;
        FhemConnectSocket();
        this.$router.push('/');
      } catch (error) {
        this.msg = error.response.data.message;
      }
    },
  },
};
</script>

<style scoped>
#cover {
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
}

.login_form {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
  border-radius: 10px;
}
</style>
