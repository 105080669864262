<template>
  <div>
    <div class="uebersicht container">
      <div>
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <cardwetter v-bind:device="devicedata.wetter"></cardwetter>
              <card v-bind:device="devicedata.bitcoin"></card>
            </div>
            <div class="col-md-6 col-xs-12">
              <card-benzinpreise></card-benzinpreise>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <card-tagesschau v-bind:device="devicedata.tagesschau"></card-tagesschau>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>

import fhemmixin from '../FhemConnection';
import CardTagesschau from '../components/CardTagesschau.vue';
import CardBenzinpreise from '../components/CardBenzinpreise.vue';

export default {
  components: {
    'card-tagesschau': CardTagesschau,
    'card-benzinpreise': CardBenzinpreise,
  },
  data() {
    return {
    };
  },
  mixins: [fhemmixin],
  async mounted() { // when the Vue app is booted up, this is run automatically.
  },
  methods: {
  },
  computed: {
    // a computed getter
  },
};
</script>
