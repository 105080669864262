module.exports = {
  chart:
  {
    type: 'line',
    options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'nearest',
        intersect: false,
      },
      title: {
        display: false,
        text: 'Wetter',
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            displayFormats: {
              hour: 'HH:mm',
              day: 'D.M.',
            },
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Zeit',
          },
        }],
        yAxes: [{
          id: 'A',
          position: 'left',
          scalePositionLeft: true,
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Temperatur',
          },
          gridLines:
          {
            color: '#ffbbbb',
          },
        },
        {
          id: 'B',
          position: 'right',
          scalePositionLeft: false,
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
          },
          display: false,
          scaleLabel: {
            display: true,
            labelString: 'Luftfeuchtigkeit',
          },
          gridLines:
          {
            color: 'rgb(0, 88, 232)',
          },
        },
        ],
      },
    },
    data: {
      datasets: [{
        label: 'Temperatur',
        data: [],
        radius: 0,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132)',
        fill: false,
        yAxisID: 'A',
        fhemDevice: 'temp_aussen',
        fhemReading: 'temperature',
      },
      {
        label: 'Luftfeuchtigkeit',
        data: [],
        radius: 0,
        backgroundColor: 'rgb(0, 88, 232)',
        borderColor: 'rgb(0, 88, 232)',
        fill: false,
        hidden: true,
        yAxisID: 'B',
        fhemDevice: 'temp_aussen',
        fhemReading: 'humidity',
      },
      ],
    },
  },
};
