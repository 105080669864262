import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Aussen from '../views/Aussen.vue';
import Wohnung from '../views/Wohnung.vue';
import Essensplan from '../views/Essensplan.vue';
import Heizung from '../views/Heizung.vue';
import Nachrichten from '../views/Nachrichten.vue';
import Waesche from '../views/Waesche.vue';
import Gartenhuette from '../views/Gartenhuette.vue';
import Admin from '../views/Admin.vue';
import TueroeffnerNfc from '../views/TueroeffnerNfc.vue';
import TueroeffnerNfcAM from '../views/TueroeffnerNfcAM.vue';
import Tuerklingel from '../views/Tuerklingel.vue';
import Ok from '../views/Ok.vue';


Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'Home',
        component: Aussen,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    {
        path: '/wohnung',
        name: 'Wohnung',
        component: Wohnung,
    },
    {
        path: '/essensplan',
        name: 'essensplan',
        component: Essensplan,
    },
    {
        path: '/heizung',
        name: 'Heizung',
        component: Heizung,
    },
    {
        path: '/nachrichten',
        name: 'Nachrichten',
        component: Nachrichten,
    },
    {
        path: '/waesche',
        name: 'Waesche',
        component: Waesche,
    },
    {
        path: '/gartenhuette',
        name: 'Gartenhuette',
        component: Gartenhuette,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
    },
    {
        path: '/opendoor',
        name: 'opendoor',
        component: TueroeffnerNfc,
    },
    {
        path: '/opendooram',
        name: 'opendooram',
        component: TueroeffnerNfcAM,
    },
    {
        path: '/ok',
        name: 'ok',
        component: Ok,
    },
    {
        path: '/tuerklingel',
        name: 'tuerklingel',
        component: Tuerklingel,
    },

];

const router = new VueRouter({
    routes,
});

export default router;
