<template>
  <div class="widget">
    <div>
      <h2>{{ device.name }}</h2>
      <table class="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th>Gesendet</th>
            <th>Empfangen</th>
            <th>Gesamt</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" width="120 px">Heute</th>
            <td>{{ todaySent }}</td>
            <td>{{ todayReceived }}</td>
            <td>{{ todaySum }}</td>
          </tr>
          <tr>
            <th scope="row">Gestern</th>
            <td>{{ sentYesterday }}</td>
            <td>{{ receivedYesterday }}</td>
            <td>{{ yesterdaySum }}</td>
          </tr>
          <tr>
            <th scope="row">akt. Monat</th>
            <td>{{ sentMonth }}</td>
            <td>{{ receivedMonth }}</td>
            <td>{{ monthSum }}</td>
          </tr>
          <tr>
            <th scope="row">letzter Monat</th>
            <td>{{ sentLastMonth }}</td>
            <td>{{ receivedLastMonth }}</td>
            <td>{{ lastMonthSum }}</td>
          </tr>
          <tr>
            <th scope="row">aktuell</th>
            <td>{{ box_rateUp }}</td>
            <td>{{ box_rateDown }}</td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">WLAN-Ger&auml;te</th>
            <td>{{ box_wlanCount }}</td>
            <td colspan="2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import fhemmixin from '../FhemConnection';

const util = require('../util');

function getGB(value, prec) {
  let f;

  if (typeof value === 'number') f = value;
  else f = parseFloat(value);

  const gb = util.f2s(f / 1024.0, prec);
  return `${gb} GB`;
}

function getRate(value, prec) {
  let f;

  if (typeof value === 'number') f = value;
  else f = parseFloat(value);

  const gb = util.f2s((f * 8) / 1024.0, prec);
  return `${gb} MBit/s`;
}

export default {
  name: 'CardInternet',
  props: ['device'],
  mixins: [fhemmixin],
  data() {
    return {};
  },
  computed: {
    todaySent() {
      return getGB(this.device.values.todaySent.val.val, 2);
    },
    todayReceived() {
      return getGB(this.device.values.todayReceived.val.val, 2);
    },
    todaySum() {
      const sum =
        parseFloat(this.device.values.todaySent.val.val) +
        parseFloat(this.device.values.todayReceived.val.val);
      return getGB(sum, 2);
    },
    sentYesterday() {
      return getGB(this.device.values.sentYesterday.val.val, 1);
    },
    receivedYesterday() {
      return getGB(this.device.values.receivedYesterday.val.val, 1);
    },
    yesterdaySum() {
      const sum =
        parseFloat(this.device.values.sentYesterday.val.val) +
        parseFloat(this.device.values.receivedYesterday.val.val);
      return getGB(sum, 1);
    },
    sentMonth() {
      const sum =
        parseFloat(this.device.values.sentMonth.val.val) +
        parseFloat(this.device.values.todaySent.val.val);
      return getGB(sum);
    },
    receivedMonth() {
      const sum =
        parseFloat(this.device.values.receivedMonth.val.val) +
        parseFloat(this.device.values.todayReceived.val.val);
      return getGB(sum);
    },
    sentLastMonth() {
      return getGB(this.device.values.sentLastMonth.val.val, 0);
    },
    receivedLastMonth() {
      return getGB(this.device.values.receivedLastMonth.val.val, 0);
    },
    monthSum() {
      const sum =
        parseFloat(this.device.values.sentMonth.val.val) +
        parseFloat(this.device.values.receivedMonth.val.val) +
        parseFloat(this.device.values.todaySent.val.val) +
        parseFloat(this.device.values.todayReceived.val.val);
      return getGB(sum, 0);
    },
    lastMonthSum() {
      const sum =
        parseFloat(this.device.values.sentLastMonth.val.val) +
        parseFloat(this.device.values.receivedLastMonth.val.val);
      return getGB(sum, 0);
    },
    box_rateUp() {
      return getRate(this.device.values.box_rateUp.val.val);
    },
    box_rateDown() {
      return getRate(this.device.values.box_rateDown.val.val);
    },
    box_wlanCount() {
      return this.filterData(this.device.values.box_wlanCount);
    },
  },
  components: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  text-align: left;
}
</style>
