<template>
  <div>
    <div @click="toggleVisible">
      Diagramm
      <font-awesome-icon v-if="!visible" icon="angle-double-down" class="iconcolapse" />
      <font-awesome-icon v-if="visible" icon="angle-double-up" class="iconcolapse" />
    </div>
    <div v-if="visible" class="container">
      <line-chart
        :styles="myStyles"
        :chartData="cd.data"
        :options="cd.options"
        ref="Chart"
      />
      <div class="contenedor">
        <span class="button">
          <b-dropdown
            id="dropdown-left"
            size="sm"
            :text="timeValue.toString()"
            variant="primary"
            class="m-2 dropdownmenu"
          >
            <b-dropdown-item
              v-for="index in 10"
              :key="index"
              @click="setTimeValue(index)"
              >{{ index }}</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown
            id="dropdown-left"
            size="sm"
            :text="timeUnitHuman"
            variant="primary"
            class="m-2 tempdropdown"
          >
            <b-dropdown-item @click="setTimeUnit('h')">Stunden</b-dropdown-item>
            <b-dropdown-item @click="setTimeUnit('d')">Tage</b-dropdown-item>
            <b-dropdown-item @click="setTimeUnit('w')">Wochen</b-dropdown-item>
            <b-dropdown-item @click="setTimeUnit('m')">Monate</b-dropdown-item>
            <b-dropdown-item @click="setTimeUnit('y')">Jahre</b-dropdown-item>
          </b-dropdown>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from './Chart.vue';

export default {
  name: 'LineChartContainer',
  components: { LineChart },
  props: ['chartDefinition'],
  data() {
    return {
      cd: this.chartDefinition,
      timeValue: 1,
      timeUnit: 'd',
      visible: false,
      myStyles: {
        height: '300px',
        width: '100%',
        position: 'relative',
        padding: '0px',
        margin: '0px',
      },
    };
  },
  computed: {
    timeUnitHuman() {
      if (this.timeUnit === 'h') return 'Stunden';
      if (this.timeUnit === 'd') return 'Tage';
      if (this.timeUnit === 'w') return 'Wochen';
      if (this.timeUnit === 'm') return 'Monate';
      if (this.timeUnit === 'y') return 'Jahre';
      return '';
    },
  },
  async mounted() {
    if (this.visible) {
      this.loadChart();
    }
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible;
      if (this.visible) {
        this.loadChart();
      }
    },
    loadChart() {
      const ds = this.cd.data.datasets;

      ds.forEach((part, index) => {
        const dsdata = ds[index];
        dsdata.data = [];
        dsdata.loaded = false;
        this.$http
          .get('api/chart/', {
            mode: 'no-cors',
            params: {
              device: ds[index].fhemDevice,
              reading: ds[index].fhemReading,
              timeValue: this.timeValue,
              timeUnit: this.timeUnit,
            },
          })
          .then((response) => {
            this.updataChartData(response, index);
          });
      });
    },
    updataChartData(data, index) {
      data.data.forEach((element) => {
        this.cd.data.datasets[index].data.push({
          t: element.timestamp,
          y: Math.round(element.value * 100) / 100,
        });
      });
      this.$refs.Chart.reload();
    },
    setTimeValue(val) {
      this.timeValue = val;
      this.loadChart();
    },
    setTimeUnit(val) {
      this.timeUnit = val;
      this.loadChart();
    },
  },
};
</script>

<style scoped>
.contenedor {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
</style>
