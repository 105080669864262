<template>
<div class="widget">
  <div>
    <h2>Benzinpreise</h2>
  <div>
    <b-table hover
      :items="items"
      :fields="fields"
      :sort-by="'e5'"
      :per-page="perPage"
      :current-page="currentPage"
      id="benzinpreis-table">
        <template #cell(e5)="data">
          <span v-html="formatPreis(data.value)"></span>
        </template>
        <template #cell(e10)="data">
          <span v-html="formatPreis(data.value)"></span>
        </template>
        <template #cell(diesel)="data">
          <span v-html="formatPreis(data.value)"></span>
        </template>
    </b-table>
 <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="benzinpreis-table"
      :hide-goto-end-buttons="true"
    ></b-pagination>
  </div>
  </div>
</div>
</template>

<script>
import fhemmixin from '../FhemConnection';

export default {
  name: 'CardBenzinpreise',
  props: ['device'],
  mixins: [fhemmixin],
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      items: [],
      fields: [
        { key: 'name', sortable: false, label: 'Tankstelle' },
        { key: 'e5', sortable: true },
        { key: 'e10', sortable: true },
        { key: 'diesel', sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    formatPreis(x) {
      if (!x) return '';

      let ret = x.toString().substring(0, 4);
      ret += '<sup>';
      ret += x.toString().substring(4, 5);
      ret += '</sup>';
      return ret;
    },
  },
  mounted() {
    this.$http.get('/api/benzinpreise')
      .then((response) => {
        this.items = response.data;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.pagination {
  background: transparent;
}

</style>
