<template>
<div class="widget">
  <div>
    <h2>{{ device.name }}</h2>
      <table  class="table table-sm">
        <tbody>
          <tr>
            <td>Sonne</td>
            <td>{{ filterData(device.values.sonnenaufgang) }}
                bis
                {{ filterData(device.values.sonnenuntergang) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.temperatur.caption }}</td>
            <td>{{ filterData(device.values.temperatur) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.luftfeuchtigkeit.caption }}</td>
            <td>{{ filterData(device.values.luftfeuchtigkeit) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.luftdruck.caption }}</td>
            <td>{{ filterData(device.values.luftdruck) }}</td>
          </tr>
          <tr>
            <td>min/max heute</td>
            <td>min. {{ filterData(device.values.min) }} /
                max. {{ filterData(device.values.max) }}</td>
          </tr>
          <tr>
            <td>min/max gestern</td>
            <td>min. {{ filterData(device.values.mingestern) }} /
                max. {{ filterData(device.values.maxgestern) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.wind.caption }}</td>
            <td>{{ wind }}</td>
          </tr>
          <tr>
            <td>Regen</td>
            <td>{{ filterData(device.values.regenstunde) }} pro Stunde<br>
                {{ filterData(device.values.regentag) }} heute
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <chart :chartDefinition="device.chart"></chart>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</div>
</template>

<script>
import fhemmixin from '../FhemConnection';
import LineChartContainer from './ChartContainer.vue';

const util = require('../util');

export default {
  name: 'CardWetter',
  props: ['device'],
  mixins: [fhemmixin],
  data() {
    return {
    };
  },
  components: {
    chart: LineChartContainer,
  },
  computed: {
    wind() {
      if (!this.device.values.wind.val.val) {
        return '';
      }
      const windspeedMS = parseFloat(this.device.values.wind.val.val);
      let text = '';
      if (windspeedMS < 0.3) text = '0: Windstille, Flaute';
      else if (windspeedMS < 1.6) text = '1: leiser Zug';
      else if (windspeedMS < 3.4) text = '2: leichte Brise';
      else if (windspeedMS < 5.5) text = '3: schwache Brise';
      else if (windspeedMS < 8.0) text = '4: mäßige Brise';
      else if (windspeedMS < 10.8) text = '5: frische Brise';
      else if (windspeedMS < 13.9) text = '6: starker Wind';
      else if (windspeedMS < 17.2) text = '7: steifer Wind';
      else if (windspeedMS < 20.8) text = '8: stürmischer Wind';
      else if (windspeedMS < 24.5) text = '9: Sturm';
      else if (windspeedMS < 28.5) text = '10: schwerer Sturm';
      else if (windspeedMS < 32.7) text = '11: orkanartiger Sturm';
      else text = '12: Orkan';

      text += ' (';
      text += util.f2s(windspeedMS * 3.6, 1);
      text += ' km/h)';

      return text;
    },
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

table {
  text-align: left;

}

</style>
