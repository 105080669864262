<template>
<div class="widget">
  <div>
    <h2>{{ device.name }}</h2>
      <table  class="table table-sm">
        <tbody>
          <tr>
            <td colspan="2">
              <div class="contenedor">
                <button @click="BierAdd1" class="btn btn-dark">+ 0,1</button>
                <button @click="BierAdd2" class="btn btn-dark">+ 0,2</button>
                <button @click="BierAdd3" class="btn btn-dark">+ 0,3</button>
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ device.values.fasswechsel.caption }}</td>
            <td>{{ filterData(device.values.fasswechsel) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.inhalt.caption }}</td>
            <td>{{ filterData(device.values.inhalt) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.aenderung.caption }}</td>
            <td>{{ filterData(device.values.aenderung) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.heute.caption }}</td>
            <td>{{ filterData(device.values.heute) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.bier_heute.caption }}</td>
            <td><span v-html="bier_detail"></span></td>
          </tr>
          <tr>
            <td>{{ device.values.gestern.caption }}</td>
            <td>{{ filterData(device.values.gestern) }}</td>
          </tr>
        </tbody>
      </table>
  </div>
</div>
</template>

<script>
import fhemmixin from '../FhemConnection';

// const util = require('../util');

export default {
  name: 'CardBierzaehler',
  props: ['device'],
  mixins: [fhemmixin],
  data() {
    return {
    };
  },
  computed: {
    bier_detail() {
      return this.device.values.bier_heute.val.val.replace(/\|/g, '<br />');
    },
  },
  methods: {
    BierAdd1() {
      this.fhemCommand('setreading MQTT2_bierzaehler add_1 1');
    },
    BierAdd2() {
      this.fhemCommand('setreading MQTT2_bierzaehler add_2 1');
    },
    BierAdd3() {
      this.fhemCommand('setreading MQTT2_bierzaehler add_3 1');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

table {
  text-align: left;
}

.contenedor{
  width:100%;
  height:100%;
  display:flex;
  justify-content: space-around;
}

</style>
