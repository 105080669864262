<template>
  <div>
    <div class="uebersicht container">
      <div>
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <card v-bind:device="devicedata.gartenhuette"></card>
            </div>
            <div class="col-md-6 col-xs-12">
              <cardbierzaehler v-bind:device="devicedata.bierzaehler"></cardbierzaehler>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>

import fhemmixin from '../FhemConnection';
import CardBierzaehler from '../components/CardBierzaehler.vue';

export default {
  components: {
    cardbierzaehler: CardBierzaehler,
  },
  data() {
    return {
    };
  },
  mixins: [fhemmixin],
  methods: {
  },
  computed: {
    // a computed getter
  },
};
</script>
