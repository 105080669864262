<template>
  <span class="dropdown-button">
    <b-dropdown
      id="dropdown-left"
      size="sm"
      :text="filterData(value)"
      variant="primary"
      class="m-2 fhemdropdown"
    >
      <b-dropdown-item
        v-for="dropdown in value.dropdown"
        v-bind:value="dropdown"
        v-bind:key="dropdown.id"
        @click="execCommand(dropdown.id)"
        >{{ dropdown.value }}</b-dropdown-item
      >
    </b-dropdown>
  </span>
</template>

<script>
import fhemmixin from '../FhemConnection';

export default {
  name: 'DropdownButton',
  props: {
    value: Object,
  },
  computed: {},
  mixins: [fhemmixin],
  data() {
    return {};
  },
  components: {},
  methods: {
    execCommand(value) {
      const cmd = this.value.fhem_cmd.replace('$', value);
      this.fhemCommand(cmd);
      // eslint-disable-next-line no-console
      console.log(cmd);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* .fhemdropdown .dropdown-menu {
  background-color: #3d4755;
}
.fhemdropdown .dropdown-menu .dropdown-item {
  background-color: #3d4755;
}
.fhemdropdown > button {
  background-color: #3d4755;
  height: 28px;
} */
</style>
