<template>
<div class="widget">
  <div>
    <h2>Abfallkalender</h2>
  <div>
    <b-table hover
      :items="items"
      :fields="fields"
      :sort-by="'tage'"
      id="abfall-table">
        <template #cell(name)="row">
          <div class="sbcolor-box sbfloating-box abfall-icon"
               v-bind:style="{ backgroundColor: row.item.color}"></div>
          <span>{{row.item.name}}</span>
        </template>
    </b-table>
  </div>
  </div>
</div>
</template>

<script>
import fhemmixin from '../FhemConnection';

export default {
  name: 'CardAbfallkalender',
  props: ['device'],
  mixins: [fhemmixin],
  data() {
    return {
      items: [],
      fields: [
        { key: 'name', label: 'Abfall' },
        { key: 'tage' },
        { key: 'wochentag', label: 'Tag' },
        { key: 'datum' },
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
  },
  mounted() {
    this.$http.get('/api/abfallkalender')
      .then((response) => {
        this.items = response.data;
      });
  },
};
</script>

<style>

.abfall-icon
{
  border-radius: 10px;
}

.sbfloating-box {
  float: left;
}

.sbcolor-box {
  width: 20px;
  height: 20px;
  vertical-align: baseline;
  margin-right: 10px;
}

</style>
