<template>
  <div class="widget">
    <div>
      <h2>{{ device.name }}</h2>
      <table class="table table-sm">
        <tbody>
          <tr v-for="value in device.values" v-bind:value="value" v-bind:key="value.id">
            <td>{{ value.caption }}</td>
            <td v-if="value.fhem_typ === 'switch'">
              <toggle-button
                v-model="value.val.val_bool"
                :sync="true"
                @change="switchChanged(value.fhem_cmd, value.val.val_bool)"
              />
              <span v-html="getTimestamp(value)"></span>
            </td>
            <td v-else-if="value.fhem_typ === 'dropdown'">
              <dropdown-button :value="value" />
            </td>
            <td v-else-if="value.fhem_typ === 'temperature'">
              <temperature-button
                :desiredTemperature="filterData(value)"
                :fhemCommand="value.fhem_cmd"
              >
              </temperature-button>
            </td>
            <td v-else v-html="filterData(value)"></td>
          </tr>
          <tr v-if="hasChart">
            <td colspan="2">
              <chart :chartDefinition="this.device.chart"></chart>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import fhemmixin from '../FhemConnection';
import LineChartContainer from './ChartContainer.vue';

import TemperatureButton from './TemperatureButton.vue';
import DropDownButton from './DropDownButton.vue';

export default {
  name: 'Card',
  props: ['device'],
  mixins: [fhemmixin],
  data() {
    return {};
  },
  components: {
    'temperature-button': TemperatureButton,
    'dropdown-button': DropDownButton,
    chart: LineChartContainer,
  },
  methods: {},
  computed: {
    hasChart() {
      return this.device.chart;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
