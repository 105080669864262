<template>
<div class="widget">
  <div>
    <h2>{{ device.name }}</h2>
    <div>vom {{ filterData(device.values.zeit) }}</div>
      <div class="embed-container">
        <iframe id="iframe_tagesschau"
            frameborder="0"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
            width="100%"
            height="100%"
            v-bind:src="device.values.url.val.val">
        </iframe>
      </div>
  </div>
</div>
</template>

<script>
import fhemmixin from '../FhemConnection';

export default {
  name: 'CardTagesschau',
  props: ['device'],
  mixins: [fhemmixin],
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.embed-container {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16x9 */
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
}
.embed-container iframe {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
