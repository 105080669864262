/* eslint-disable eol-last */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
const ChartBTC = require('../charts/chartBtc');
const ChartWetter = require('../charts/chartWetter');

const defaultMaxAgeTemp = 1200;
const defaultMaxAgeHeizung = 1800;
const defaultMaxAgePuffer = 7200;

module.exports = {
    deviceDefinition: {
        aussen_ww3: {
            name: 'Außen',
            values: {
                aussenlicht: {
                    caption: 'Aussenlicht',
                    fhem_device: 'MQTT2_lichtHaus',
                    fhem_reading: 'POWER',
                    fhem_typ: 'switch',
                    fhem_cmd: 'set MQTT2_lichtHaus $',
                },
                carport: {
                    caption: 'Carport',
                    fhem_device: 'MQTT2_ShellyCarport',
                    fhem_reading: 'relay_0',
                    fhem_typ: 'switch',
                    fhem_cmd: 'set MQTT2_ShellyCarport $',
                },
                fischteich: {
                    caption: 'Fischteich',
                    fhem_device: 'MQTT2_fischteich',
                    fhem_reading: 'params_switch_0_output',
                    fhem_typ: 'switch',
                    fhem_cmd: 'set MQTT2_fischteich $',
                },
                temperatur: {
                    caption: 'Temperatur',
                    fhem_device: 'wetter_owm',
                    fhem_reading: 'main_temp_c',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                luftfeuchtigkeit: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'wetter_owm',
                    fhem_reading: 'main_humidity',
                    unit: '%',
                    max_age: defaultMaxAgeTemp,
                },
            },
        },
        aussen_am2: {
            name: 'Außen',
            values: {
                aussenlicht: {
                    caption: 'Aussenlicht',
                    fhem_device: 'MQTT2_aussenlicht',
                    fhem_reading: 'state',
                    fhem_typ: 'switch',
                    fhem_cmd: 'set MQTT2_aussenlicht $',
                },
                temperatur: {
                    caption: 'Temperatur',
                    fhem_device: 'temp_aussen',
                    fhem_reading: 'temperature',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                luftfeuchtigkeit: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'temp_aussen',
                    fhem_reading: 'humidity',
                    unit: '%',
                    prec: 0,
                    max_age: defaultMaxAgeTemp,
                },
                post_vorhanden: {
                    caption: 'Post vorhanden',
                    fhem_device: 'MQTT2_zigbee_briefkasten',
                    fhem_reading: 'post_vorhanden',
                    format: 'icon',
                },
            },
        },
        wohnung: {
            name: 'Wohnung',
            values: {
                haustuer: {
                    caption: 'Haustür',
                    fhem_device: 'MQTT2_zigbee_tuerHaus',
                    fhem_reading: 'state',
                    show_timestamp: true,
                    show_timestamp_prefix: 'seit',
                },
                wohnungstuer: {
                    caption: 'Wohnungstür',
                    fhem_device: 'MQTT2_zigbee_tuerWohnung',
                    fhem_reading: 'state',
                    show_timestamp: true,
                    show_timestamp_prefix: 'seit',
                },
                fenster: {
                    caption: 'Offene Fenster',
                    fhem_device: 'di_uiTable_windows',
                    fhem_reading: 'windows',
                },
            }
        },
        staubsauger: {
            name: 'Staubsauger',
            values: {
                status: {
                    caption: 'Status',
                    fhem_device: 'MQTT2_deebot',
                    fhem_reading: 'deviceStatus',
                },
                harmony: {
                    caption: 'Kommando',
                    fhem_device: 'MQTT2_deebot',
                    fhem_reading: 'dummytext',
                    fhem_typ: 'dropdown',
                    fhem_cmd: 'set MQTT2_deebot $',
                    dropdown: [
                        { id: 'Pause', value: 'Pause' },
                        { id: 'Aufladen', value: 'Aufladen' },
                        { id: 'AutoReinigung', value: 'Auto Reinigung' },

                        { id: 'Reinigung_flur', value: 'Reinigung Flur' },
                        { id: 'Reinigung_wohnzimmer', value: 'Reinigung Wohnzimmer' },
                        { id: 'Reinigung_kueche', value: 'Reinigung Küche' },
                        { id: 'Reinigung_bad', value: 'Reinigung Bad' },
                        { id: 'Reinigung_schlafzimmer', value: 'Reinigung Schlafzimmer' },
                        { id: 'Reinigung_schlafzimmer_ela', value: 'Reinigung Schlafzimmer Ela' },
                    ],
                },
            }
        },
        wohnzimmer: {
            name: 'Wohnzimmer',
            values: {
                licht: {
                    caption: 'Licht',
                    fhem_device: 'MQTT2_shellyWohnzimmer_CH1',
                    fhem_typ: 'switch',
                    fhem_reading: 'state',
                    fhem_cmd: 'set MQTT2_shellyWohnzimmer_CH1 $',
                },
                licht2: {
                    caption: 'Licht Esstisch',
                    fhem_device: 'MQTT2_shellyWohnzimmer_CH2',
                    fhem_typ: 'switch',
                    fhem_reading: 'state',
                    fhem_cmd: 'set MQTT2_shellyWohnzimmer_CH2 $',
                },
                licht3: {
                    caption: 'Licht Sofa',
                    fhem_device: 'MQTT2_shellyWohnzimmer_CH3',
                    fhem_typ: 'switch',
                    fhem_reading: 'state',
                    fhem_cmd: 'set MQTT2_shellyWohnzimmer_CH3 $',
                },
                stehlampe: {
                    caption: 'Stehlampe',
                    fhem_device: 'MQTT2_stehlampe',
                    fhem_typ: 'switch',
                    fhem_reading: 'state',
                    fhem_cmd: 'set MQTT2_stehlampe $',
                },
                weihnachtsbaum: {
                    caption: 'Weihnachtsbaum',
                    fhem_device: 'MQTT2_Weihnachtsbaum',
                    fhem_typ: 'switch',
                    fhem_reading: 'state',
                    fhem_cmd: 'set MQTT2_Weihnachtsbaum $',
                },
                schrankbeleuchtung: {
                    caption: 'Schrankbeleuchtung',
                    fhem_device: 'MQTT2_zigbee_schrankbeleuchtung',
                    fhem_typ: 'switch',
                    fhem_reading: 'state',
                    fhem_cmd: 'set MQTT2_zigbee_schrankbeleuchtung $',
                },
                heizung: {
                    caption: 'Heizung',
                    fhem_device: 'MQTT2_wohnzimmer',
                    fhem_reading: 'setPointTemperature',
                    unit: '°C',
                    fhem_typ: 'temperature',
                    fhem_cmd: 'set MQTT2_wohnzimmer setPointTemperature $',
                    max_age: defaultMaxAgeTemp,
                },
                klima_betrieb: {
                    caption: 'Klimaanlage',
                    fhem_device: 'MQTT2_KlimaWohnzimmer',
                    fhem_typ: 'switch',
                    fhem_reading: 'betrieb',
                    fhem_cmd: 'set MQTT2_KlimaWohnzimmer $',
                },
                klima_temp: {
                    caption: 'Klima Temperatur',
                    fhem_device: 'MQTT2_KlimaWohnzimmer',
                    fhem_reading: 'target',
                    fhem_typ: 'temperature',
                    fhem_cmd: 'set MQTT2_KlimaWohnzimmer temperatur $',
                    unit: '°C',
                },
                temperature: {
                    caption: 'Temperatur',
                    fhem_device: 'temp_wohnzimmer',
                    fhem_reading: 'temperature',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                humidity: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'temp_wohnzimmer',
                    fhem_reading: 'humidity',
                    unit: '%',
                    prec: 0,
                    max_age: defaultMaxAgeTemp,
                },

                // harmony: {
                //     caption: 'Medien',
                //     fhem_device: 'harmony',
                //     fhem_reading: 'currentActivity',
                //     fhem_typ: 'dropdown',
                //     fhem_cmd: 'set harmony activity $',
                //     dropdown: [
                //         { id: 'PowerOff', value: 'Aus' },
                //         { id: 'Fernsehen', value: 'Fernsehen' },
                //         { id: 'Fire.TV.sehen', value: 'Fire TV' },
                //         { id: 'Fire.TV.Audio', value: 'Musik' },
                //     ],
                // },
            },
        },
        buero: {
            name: 'Büro',
            values: {
                heizung: {
                    caption: 'Heizung',
                    fhem_device: 'MQTT2_buero',
                    fhem_reading: 'setPointTemperature',
                    unit: '°C',
                    fhem_typ: 'temperature',
                    fhem_cmd: 'set MQTT2_buero setPointTemperature $',
                    max_age: defaultMaxAgeTemp,
                },
                klima_betrieb: {
                    caption: 'Klimaanlage',
                    fhem_device: 'MQTT2_KlimaBuero',
                    fhem_typ: 'switch',
                    fhem_reading: 'betrieb',
                    fhem_cmd: 'set MQTT2_KlimaBuero $',
                },
                klima_temp: {
                    caption: 'Klima Temperatur',
                    fhem_device: 'MQTT2_KlimaBuero',
                    fhem_reading: 'target',
                    fhem_typ: 'temperature',
                    fhem_cmd: 'set MQTT2_KlimaBuero temperatur $',
                    unit: '°C',
                },
                temperature: {
                    caption: 'Temperatur',
                    fhem_device: 'temp_buero',
                    fhem_reading: 'temperature',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                humidity: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'temp_buero',
                    fhem_reading: 'humidity',
                    unit: '%',
                    prec: 0,
                    max_age: defaultMaxAgeTemp,
                },
            },
        },
        waschmaschine: {
            name: 'Waschmaschine',
            values: {
                betrieb: {
                    caption: 'Betrieb',
                    fhem_device: 'waschmaschine_betrieb',
                    fhem_reading: 'state',
                    format: 'icon',
                },
                start: {
                    caption: 'Letzter Start',
                    fhem_device: 'waschmaschine_betrieb',
                    fhem_reading: 'lastrun',
                    format: 'timestamp',
                },
                dauer: {
                    caption: 'Dauer',
                    fhem_device: 'waschmaschine_betrieb',
                    fhem_reading: 'dauer',
                    format: 'seconds',
                },
                person: {
                    caption: 'Person',
                    fhem_device: 'waschmaschine_betrieb',
                    fhem_reading: 'person',
                },
                kw: {
                    caption: 'Verbrauch',
                    fhem_device: 'waschmaschine_betrieb',
                    fhem_reading: 'kwdiff',
                    unit: 'KW',
                    prec: 2,
                },
                wasser: {
                    caption: 'Wasser',
                    fhem_device: 'waschmaschine_betrieb',
                    fhem_reading: 'liter_prev',
                    unit: 'Liter',
                    prec: 0,
                },
            },
        },
        waschmaschine_ela: {
            name: 'Waschmaschine Ela',
            values: {
                betrieb: {
                    caption: 'Betrieb',
                    fhem_device: 'waschmaschine_betrieb_ela',
                    fhem_reading: 'state',
                    format: 'icon',
                },
                start: {
                    caption: 'Letzter Start',
                    fhem_device: 'waschmaschine_betrieb_ela',
                    fhem_reading: 'lastrun',
                    format: 'timestamp',
                },
                dauer: {
                    caption: 'Dauer',
                    fhem_device: 'waschmaschine_betrieb_ela',
                    fhem_reading: 'dauer',
                    format: 'seconds',
                },
                person: {
                    caption: 'Person',
                    fhem_device: 'waschmaschine_betrieb_ela',
                    fhem_reading: 'person',
                },
                kw: {
                    caption: 'Verbrauch',
                    fhem_device: 'waschmaschine_betrieb_ela',
                    fhem_reading: 'kwdiff',
                    unit: 'KW',
                    prec: 2,
                },
            },
        },
        trockner: {
            name: 'Trockner',
            values: {
                betrieb: {
                    caption: 'Betrieb',
                    fhem_device: 'trockner_betrieb',
                    fhem_reading: 'state',
                    format: 'icon',
                },
                start: {
                    caption: 'Letzter Start',
                    fhem_device: 'trockner_betrieb',
                    fhem_reading: 'lastrun',
                    format: 'timestamp',
                },
                dauer: {
                    caption: 'Dauer',
                    fhem_device: 'trockner_betrieb',
                    fhem_reading: 'dauer',
                    format: 'seconds',
                },
                person: {
                    caption: 'Person',
                    fhem_device: 'trockner_betrieb',
                    fhem_reading: 'person',
                },
                kw: {
                    caption: 'Verbrauch',
                    fhem_device: 'trockner_betrieb',
                    fhem_reading: 'kwdiff',
                    unit: 'KW',
                    prec: 2,
                },
            },
        },
        waschmaschine_conny: {
            name: 'Waschmaschine Conny',
            values: {
                betrieb: {
                    caption: 'Betrieb',
                    fhem_device: 'waschmaschine_betrieb_conny',
                    fhem_reading: 'state',
                    format: 'icon',
                },
                start: {
                    caption: 'Letzter Start',
                    fhem_device: 'waschmaschine_betrieb_conny',
                    fhem_reading: 'lastrun',
                    format: 'timestamp',
                },
                dauer: {
                    caption: 'Dauer',
                    fhem_device: 'waschmaschine_betrieb_conny',
                    fhem_reading: 'dauer',
                    format: 'seconds',
                },
                person: {
                    caption: 'Person',
                    fhem_device: 'waschmaschine_betrieb_conny',
                    fhem_reading: 'person',
                },
                kw: {
                    caption: 'Verbrauch',
                    fhem_device: 'waschmaschine_betrieb_conny',
                    fhem_reading: 'kwdiff',
                    unit: 'KW',
                    prec: 2,
                },
            },
        },
        trockner_conny: {
            name: 'Trockner Conny',
            values: {
                betrieb: {
                    caption: 'Betrieb',
                    fhem_device: 'trockner_betrieb_muehlengraben',
                    fhem_reading: 'state',
                    format: 'icon',
                },
                start: {
                    caption: 'Letzter Start',
                    fhem_device: 'trockner_betrieb_muehlengraben',
                    fhem_reading: 'lastrun',
                    format: 'timestamp',
                },
                dauer: {
                    caption: 'Dauer',
                    fhem_device: 'trockner_betrieb_muehlengraben',
                    fhem_reading: 'dauer',
                    format: 'seconds',
                },
                person: {
                    caption: 'Person',
                    fhem_device: 'trockner_betrieb_muehlengraben',
                    fhem_reading: 'person',
                },
                kw: {
                    caption: 'Verbrauch',
                    fhem_device: 'trockner_betrieb_muehlengraben',
                    fhem_reading: 'kwdiff',
                    unit: 'KW',
                    prec: 2,
                },
                leistung: {
                    caption: 'Leistung',
                    fhem_device: 'MQTT2_zigbee_trocknerConny',
                    fhem_reading: 'power',
                    unit: 'W',
                    prec: 0,
                },
            },
        },
        schlafzimmerBasti: {
            name: 'Schlafzimmer Basti',
            values: {
                rollo: {
                    caption: 'Rollo',
                    fhem_device: 'MQTT2_rolloBasti',
                    fhem_typ: 'dropdown',
                    fhem_reading: 'params_cover_0_current_pos',
                    unit: "%",
                    fhem_cmd: 'set MQTT2_rolloBasti $',
                    dropdown: [
                        { id: 'open', value: 'Offen' },
                        { id: 'pct 75', value: '75 %' },
                        { id: 'pct 50', value: '50 %' },
                        { id: 'pct 25', value: '25 %' },
                        { id: 'pct 10', value: '10 %' },
                        { id: 'close', value: 'Geschlossen' },
                    ],
                },
                fenster: {
                    caption: 'Fenster',
                    fhem_device: 'MQTT2_zigbee_fensterSchlafzimmerBasti',
                    fhem_typ: 'window',
                    fhem_reading: 'state',
                },
                temperature: {
                    caption: 'Temperatur',
                    fhem_device: 'MQTT2_zigbee_tempSchlafzimmerBasti',
                    fhem_reading: 'temperature',
                    unit: '°C',
                    prec: 1,
                },
                humidity: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'MQTT2_zigbee_tempSchlafzimmerBasti',
                    fhem_reading: 'humidity',
                    unit: '%',
                    prec: 0,
                },
                licht: {
                    caption: 'Licht',
                    fhem_device: 'schlafzimmerled',
                    fhem_typ: 'switch',
                    fhem_reading: 'state',
                    fhem_cmd: 'set schlafzimmerled $',
                },

                wecker: {
                    caption: 'Wecker',
                    fhem_device: 'wecker',
                    fhem_reading: 'state',
                    fhem_typ: 'dropdown',
                    fhem_cmd: 'set wecker $',
                    dropdown: [
                        { id: 'disable 1', value: 'Aus' },
                        { id: 'disable 0', value: 'Ein' },
                    ],
                },
            },
        },
        schlafzimmerEla: {
            name: 'Schlafzimmer Ela',
            values: {
                rollo: {
                    caption: 'Rollo',
                    fhem_device: 'MQTT2_rolloEla',
                    fhem_typ: 'dropdown',
                    fhem_reading: 'params_cover_0_current_pos',
                    unit: "%",
                    fhem_cmd: 'set MQTT2_rolloEla $',
                    dropdown: [
                        { id: 'open', value: 'Offen' },
                        { id: 'pct 75', value: '75 %' },
                        { id: 'pct 50', value: '50 %' },
                        { id: 'pct 25', value: '25 %' },
                        { id: 'pct 10', value: '10 %' },
                        { id: 'close', value: 'Geschlossen' },
                    ],
                },
                fenster: {
                    caption: 'Fenster',
                    fhem_device: 'MQTT2_zigbee_fensterSchlafzimmerEla',
                    fhem_typ: 'window',
                    fhem_reading: 'state',
                },
                temperature: {
                    caption: 'Temperatur',
                    fhem_device: 'temp_schlafzimmer_ela',
                    fhem_reading: 'temperature',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                humidity: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'temp_schlafzimmer_ela',
                    fhem_reading: 'humidity',
                    unit: '%',
                    prec: 0,
                    max_age: defaultMaxAgeTemp,
                },
                licht: {
                    caption: 'Licht',
                    fhem_device: 'MQTT2_elanachtlicht',
                    fhem_typ: 'switch',
                    fhem_reading: 'state',
                    fhem_cmd: 'set MQTT2_elanachtlicht $',
                },
            },
        },
        bad: {
            name: 'Bad',
            values: {
                heizung: {
                    caption: 'Heizung',
                    fhem_device: 'MQTT2_bad',
                    fhem_reading: 'setPointTemperature',
                    unit: '°C',
                    fhem_typ: 'temperature',
                    fhem_cmd: 'set MQTT2_bad setPointTemperature $',
                    max_age: defaultMaxAgeTemp,
                },
                temperature: {
                    caption: 'Temperatur',
                    fhem_device: 'temp_bad',
                    fhem_reading: 'temperature',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                humidity: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'temp_bad',
                    fhem_reading: 'humidity',
                    unit: '%',
                    prec: 0,
                    max_age: defaultMaxAgeTemp,
                },
                fenster: {
                    caption: 'Fenster',
                    fhem_device: 'fenster_bad',
                    fhem_typ: 'window',
                    fhem_reading: 'state',
                },
            },
        },
        kueche: {
            name: 'Küche',
            values: {
                heizung: {
                    caption: 'Heizung',
                    fhem_device: 'MQTT2_kueche',
                    fhem_reading: 'setPointTemperature',
                    unit: '°C',
                    fhem_typ: 'temperature',
                    fhem_cmd: 'set MQTT2_kueche setPointTemperature $',
                    max_age: defaultMaxAgeTemp,
                },
                temperature: {
                    caption: 'Temperatur',
                    fhem_device: 'temp_kueche',
                    fhem_reading: 'temperature',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                humidity: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'temp_kueche',
                    fhem_reading: 'humidity',
                    unit: '%',
                    prec: 0,
                    max_age: defaultMaxAgeTemp,
                },
            },
        },
        bran: {
            name: 'Bran',
            values: {
                heizung: {
                    caption: 'Heizung',
                    fhem_device: 'MQTT2_bran',
                    fhem_reading: 'setPointTemperature',
                    unit: '°C',
                    fhem_typ: 'temperature',
                    fhem_cmd: 'set MQTT2_bran setPointTemperature $',
                    max_age: defaultMaxAgeTemp,
                },
                temperature: {
                    caption: 'Temperatur',
                    fhem_device: 'temp_bran',
                    fhem_reading: 'temperature',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                humidity: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'temp_bran',
                    fhem_reading: 'humidity',
                    unit: '%',
                    prec: 0,
                    max_age: defaultMaxAgeTemp,
                },
            },
        },
        bitcoin: {
            name: 'Bitcoin',
            chart: ChartBTC.chart,
            values: {
                btc: {
                    caption: 'BTC',
                    fhem_device: 'BTC',
                    fhem_reading: 'BTC',
                    unit: '€',
                    max_age: 60 * 60,
                },
            },
        },
        stromverbrauchWW: {
            name: 'Stromverbrauch',
            values: {
                verbrauch_akt: {
                    caption: 'Haus aktuell',
                    fhem_device: 'MQTT2_stromzaehlerWW',
                    fhem_reading: 'DWS7410_power',
                    unit: 'Watt',
                    max_age: 600,
                },
                verbrauch_heute: {
                    caption: 'Verbrauch heute',
                    fhem_device: 'MQTT2_stromzaehlerWW',
                    fhem_reading: 'statDWS7410_zaehlerstand_tarif_1Day',
                    unit: 'Kw/h',
                    max_age: 600,
                },
                verbrauch_gestern: {
                    caption: 'Verbrauch gestern',
                    fhem_device: 'MQTT2_stromzaehlerWW',
                    fhem_reading: 'statDWS7410_zaehlerstand_tarif_1DayLast',
                    unit: 'Kw/h',
                },
                verbrauch_monat: {
                    caption: 'Verbrauch dieser Monat',
                    fhem_device: 'MQTT2_stromzaehlerWW',
                    fhem_reading: 'statDWS7410_zaehlerstand_tarif_1Month',
                    unit: 'Kw/h',
                },
                verbrauch_letzter_monat: {
                    caption: 'Verbrauch letzter Monat',
                    fhem_device: 'MQTT2_stromzaehlerWW',
                    fhem_reading: 'statDWS7410_zaehlerstand_tarif_1MonthLast',
                    unit: 'Kw/h',
                },
            },
        },

        stromverbrauchWWEinspeisung: {
            name: 'Strom Einspeisung',
            values: {
                einspeisung_heute: {
                    caption: 'Einspeisung heute',
                    fhem_device: 'MQTT2_stromzaehlerWW',
                    fhem_reading: 'statDWS7410_zaehlerstand_tarif_2Day',
                    unit: 'Kw/h',
                    max_age: 600,
                },
                einspeisung_gestern: {
                    caption: 'Einspeisung gestern',
                    fhem_device: 'MQTT2_stromzaehlerWW',
                    fhem_reading: 'statDWS7410_zaehlerstand_tarif_2DayLast',
                    unit: 'Kw/h',
                },
                einspeisung_monat: {
                    caption: 'Einspeisung dieser Monat',
                    fhem_device: 'MQTT2_stromzaehlerWW',
                    fhem_reading: 'statDWS7410_zaehlerstand_tarif_2Month',
                    unit: 'Kw/h',
                },
                einspeisung_letzter_monat: {
                    caption: 'Einspeisung letzter Monat',
                    fhem_device: 'MQTT2_stromzaehlerWW',
                    fhem_reading: 'statDWS7410_zaehlerstand_tarif_2MonthLast',
                    unit: 'Kw/h',
                },
            },
        },

        stromverbrauch_akt: {
            name: 'Stromverbrauch live',
            values: {
                pv_akt: {
                    caption: 'PV aktuell',
                    fhem_device: 'MQTT2_photovoltaik',
                    fhem_reading: 'params_switch_0_apower',
                    unit: 'Watt',
                    max_age: 60 * 30,
                },
                curr_basti_ohne_pv: {
                    caption: 'Basti aktuell',
                    fhem_device: 'MQTT2_stromzaehler',
                    fhem_reading: 'curr_basti_ohne_pv',
                    unit: 'Watt',
                    max_age: 60,
                    prec: 0,
                },
                curr_basti: {
                    caption: 'Basti aktuell inkl PV',
                    fhem_device: 'MQTT2_stromzaehler',
                    fhem_reading: 'curr_basti',
                    unit: 'Watt',
                    max_age: 60,
                    prec: 0,
                },
                curr_conny: {
                    caption: 'Conny aktuell',
                    fhem_device: 'MQTT2_stromzaehler',
                    fhem_reading: 'curr_conny',
                    unit: 'Watt',
                    max_age: 60,
                    prec: 0,
                },
                curr_heizung: {
                    caption: 'Heizung aktuell',
                    fhem_device: 'MQTT2_stromzaehler',
                    fhem_reading: 'curr_heizung',
                    unit: 'Watt',
                    max_age: 600,
                    prec: 0,
                },
                curr_gesamt: {
                    caption: 'Haus insg. aktuell',
                    fhem_device: 'MQTT2_stromzaehler',
                    fhem_reading: 'curr_gesamt',
                    unit: 'Watt',
                    max_age: 60,
                    prec: 0,
                },
            }
        },

        stromverbrauch: {
            name: 'Stromverbrauch heute',
            values: {
                verbrauch_heute: {
                    caption: 'Haus heute',
                    fhem_device: 'MQTT2_stromzaehler',
                    fhem_reading: 'statSML_Total_inDay',
                    unit: 'kW/h',
                    prec: 1,
                },
                verbrauch_heute_basti: {
                    caption: 'Basti heute',
                    fhem_device: 'MQTT2_stromBasti',
                    fhem_reading: 'stat_Total_inDay',
                    unit: 'kW/h',
                    prec: 1,
                },
                out_heute_basti: {
                    caption: 'Basti heute eingespeist',
                    fhem_device: 'MQTT2_stromBasti',
                    fhem_reading: 'stat_Total_outDay',
                    unit: 'kW/h',
                    prec: 1,
                },
                pv_heute: {
                    caption: 'PV Ertrag heute',
                    fhem_device: 'MQTT2_photovoltaik',
                    fhem_reading: 'statAenergy_total_kwDay',
                    unit: 'kW/h',
                    max_age: 60 * 60 * 3,
                    prec: 1,
                    //prec: 2,
                },
            },
        },

        stromverbrauch_stat: {
            name: 'Stromverbrauch Statistik',
            values: {
            
                pv_gestern: {
                    caption: 'PV Ertrag gestern',
                    fhem_device: 'MQTT2_photovoltaik',
                    fhem_reading: 'statAenergy_total_kwDayLast',
                    unit: 'kW/h',
                    prec: 1,
                },
                pv_monat: {
                    caption: 'PV Ertrag Monat',
                    fhem_device: 'MQTT2_photovoltaik',
                    fhem_reading: 'statAenergy_total_kwMonth',
                    unit: 'kW/h',
                    prec: 1,
                },
                verbrauch_gestern: {
                    caption: 'Haus gestern',
                    fhem_device: 'MQTT2_stromzaehler',
                    fhem_reading: 'statSML_Total_inDayLast',
                    unit: 'kW/h',
                    prec: 1,
                },
                verbrauch_gestern_basti: {
                    caption: 'Basti gestern',
                    fhem_device: 'MQTT2_stromBasti',
                    fhem_reading: 'stat_Total_inDayLast',
                    unit: 'kW/h',
                    prec: 1,
                },
                out_gestern_basti: {
                    caption: 'Basti gestern eingespeist',
                    fhem_device: 'MQTT2_stromBasti',
                    fhem_reading: 'stat_Total_outDayLast',
                    unit: 'kW/h',
                    prec: 1,
                },
            },
        },        

        wetter: {
            name: 'Wetter',
            chart: ChartWetter.chart,
            values: {
                sonnenaufgang: {
                    caption: 'Sonnenaufgang',
                    fhem_device: 'wetter_owm',
                    fhem_reading: 'sys_sunrise',
                    format: 'unixTimestamp',
                    unit: 'Uhr',
                },
                sonnenuntergang: {
                    caption: 'Sonnenuntergang',
                    fhem_device: 'wetter_owm',
                    fhem_reading: 'sys_sunset',
                    format: 'unixTimestamp',
                    unit: 'Uhr',
                },
                temperatur: {
                    caption: 'Temperatur',
                    fhem_device: 'temp_aussen',
                    fhem_reading: 'temperature',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                luftfeuchtigkeit: {
                    caption: 'Luftfeuchtigkeit',
                    fhem_device: 'wetter_owm',
                    fhem_reading: 'main_humidity',
                    unit: '%',
                    max_age: defaultMaxAgeTemp,
                },
                min: {
                    caption: 'min',
                    fhem_device: 'temp_aussen',
                    fhem_reading: 'statTemperatureDayMin',
                    unit: '°C',
                    prec: 1,
                },
                max: {
                    caption: 'max',
                    fhem_device: 'temp_aussen',
                    fhem_reading: 'statTemperatureDayMax',
                    unit: '°C',
                    prec: 1,
                },
                mingestern: {
                    caption: 'mingestern',
                    fhem_device: 'temp_aussen',
                    fhem_reading: 'statTemperatureDayMinLast',
                    unit: '°C',
                    prec: 1,
                },
                maxgestern: {
                    caption: 'maxgestern',
                    fhem_device: 'temp_aussen',
                    fhem_reading: 'statTemperatureDayMaxLast',
                    unit: '°C',
                    prec: 1,
                },
                luftdruck: {
                    caption: 'Luftdruck',
                    fhem_device: 'wetter_owm',
                    fhem_reading: 'main_pressure',
                    unit: 'hPa',
                    prec: 0,
                    max_age: defaultMaxAgeTemp,
                },
                wind: {
                    caption: 'Wind',
                    fhem_device: 'wetter_owm',
                    fhem_reading: 'wind_speed',
                    max_age: defaultMaxAgeTemp,
                },
                regenstunde: {
                    caption: 'regenstunde',
                    fhem_device: 'netatmo_regen',
                    fhem_reading: 'rain_hour',
                    unit: 'mm',
                    prec: 1,
                    max_age: 30 * 60,
                },
                regentag: {
                    caption: 'regentag',
                    fhem_device: 'netatmo_regen',
                    fhem_reading: 'rain_day',
                    unit: 'mm',
                    prec: 1,
                    max_age: 60 * 60,
                },
            },
        },
        heizung_am_zirk: {
            name: 'Zirkulation',
            values: {
                zirkulation: {
                    caption: 'Zirkulation',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_typ: 'switch',
                    fhem_reading: 'Zirkulation_text',
                    fhem_cmd: 'set MQTT2_EtaHeizung zirk_$',
                    show_timestamp: true,
                    show_timestamp_prefix: 'zuletzt',
                },
            }
        },
        heizung_am_pelletstatus: {
            name: 'Pellet Brenner',
            values: {
                status: {
                    caption: 'Status',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_typ: 'switch',
                    fhem_reading: 'Pellet_EinAus_text',
                    fhem_cmd: 'set MQTT2_EtaHeizung pellet_$',
                },
            }
        },
        hk_status: {
            name: 'Heizkreis',
            values: {
                hk1: {
                    caption: 'Heizkreis 1',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_typ: 'switch',
                    fhem_reading: 'HK_Status_text',
                    fhem_cmd: 'set MQTT2_EtaHeizung hk1_$',
                },
            }
        },
        heizung_am_kessel: {
            name: 'Kessel',
            values: {
                status: {
                    caption: 'Kessel Status',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Kessel_Status_text',
                    show_timestamp: true,
                    show_timestamp_prefix: 'seit',
                },
                status2: {
                    caption: 'Twin Status',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Pellet_Status_text',
                    show_timestamp: true,
                    show_timestamp_prefix: 'seit',
                },
                abgas: {
                    caption: 'Abgas',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Abgas',
                    unit: '°C',
                    prec: 0,
                    max_age: defaultMaxAgeHeizung,
                },
                aussen: {
                    caption: 'Aussentemperatur',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Aussentemperatur',
                    unit: '°C',
                    prec: 0,
                    max_age: defaultMaxAgeHeizung,
                },
                puffer: {
                    caption: 'Puffer Ladung',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Ladezustand_Puffer',
                    unit: '%',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                puffer_1: {
                    caption: 'Puffer Temp. 1',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Puffer_1',
                    unit: '°C',
                    prec: 0,
                    max_age: defaultMaxAgeHeizung,
                },
                puffer_2: {
                    caption: 'Puffer Temp. 2',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Puffer_2',
                    unit: '°C',
                    prec: 0,
                    max_age: defaultMaxAgeHeizung,
                },
                puffer_3: {
                    caption: 'Puffer Temp. 3',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Puffer_3',
                    unit: '°C',
                    prec: 0,
                    max_age: defaultMaxAgeHeizung,
                },
                puffer_4: {
                    caption: 'Puffer Temp. 4',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Puffer_4',
                    unit: '°C',
                    prec: 0,
                    max_age: defaultMaxAgeHeizung,
                },
            }
        },
        heizung_am_solar: {
            name: 'Solar',
            values: {
                status: {
                    caption: 'Status',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Solaranlage_text',
                },
                status2: {
                    caption: '',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'SolaranlageErg_text',
                },
                kollektor: {
                    caption: 'Kollektortemperatur',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Kollektortemperatur',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                pumpe: {
                    caption: 'Pumpe',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'SolaranlagePumpe',
                    unit: '%',
                    prec: 0,
                },
            }
        },
        heizung_am_stat: {
            name: 'Verbrauch / Statistik',
            values: {
                curr_heizung: {
                    caption: 'Strom aktuell',
                    fhem_device: 'MQTT2_stromzaehler',
                    fhem_reading: 'curr_heizung',
                    unit: 'Watt',
                    max_age: 600,
                    prec: 0,
                },
                VollaststundenHolz_h: {
                    caption: 'Stunden Holz',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'VollaststundenHolz_h',
                    unit: 'Std.',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                Vollaststunden_h: {
                    caption: 'Stunden Pellets',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Vollaststunden_h',
                    unit: 'Std.',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                Waermezaehler: {
                    caption: 'Waermezaehler',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Waermezaehler',
                    unit: 'kWh',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                Waermezaehler_heute: {
                    caption: 'Waermezaehler',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'statWaermezaehlerDay',
                    unit: 'kWh',
                    prec: 1,
                },
                statPellet_GesamtverbrauchDay: {
                    caption: 'Pelletverbrauch heute',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'statPellet_GesamtverbrauchDay',
                    unit: 'kg',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                statPellet_GesamtverbrauchDayLast: {
                    caption: 'Pelletverbrauch gestern',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'statPellet_GesamtverbrauchDayLast',
                    unit: 'kg',
                    prec: 1,
                },
                statPellet_GesamtverbrauchMonth: {
                    caption: 'Pelletverbrauch akt Monat',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'statPellet_GesamtverbrauchMonth',
                    unit: 'kg',
                    prec: 1,
                },
                statPellet_GesamtverbrauchMonthLast: {
                    caption: 'Pelletverbrauch letzter Monat',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'statPellet_GesamtverbrauchMonthLast',
                    unit: 'kg',
                    prec: 1,
                },
                Pellet_Vorrat: {
                    caption: 'Pellet Vorrat',
                    fhem_device: 'MQTT2_EtaHeizung',
                    fhem_reading: 'Pellet_Vorrat',
                    unit: 'kg',
                    prec: 0,
                },
            }
        },
        heizung: {
            name: 'Heizung',
            values: {
                status: {
                    caption: 'Status',
                    fhem_device: 'MQTT2_brenner',
                    fhem_reading: 'heizbetrieb',
                    show_timestamp: true,
                    show_timestamp_prefix: 'seit',
                    format: 'icon',
                },
                abgas: {
                    caption: 'Abgas',
                    fhem_device: 'MQTT2_brenner',
                    fhem_reading: 'abgas',
                    unit: '°C',
                    prec: 0,
                    max_age: defaultMaxAgeHeizung,
                },
                temperatur: {
                    caption: 'Temperatur',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'Kesseltemperatur',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                warmwasserist: {
                    caption: 'Warmwasser',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'WW-Isttemperatur',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                warmwassersoll: {
                    caption: '',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'WW-Haupttemperatur',
                    unit: '°C',
                    prec: 1,
                },
                vorlauf1: {
                    caption: 'Vorlauf',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'HK1-Vorlauftemperatur',
                    unit: '°C',
                    prec: 0,
                    max_age: defaultMaxAgeHeizung,
                },
                vorlauf2: {
                    caption: 'Vorlauf',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'HK2-Vorlauftemperatur',
                    unit: '°C',
                    prec: 0,
                    max_age: defaultMaxAgeHeizung,
                },
                aussentemperatur: {
                    caption: 'Aussentemperatur',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'Aussentemperatur',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                solartemperatur: {
                    caption: 'Solar Temperatur',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'Solar_Sensor_Temperatur_Kollektor',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                solar_pumpe: {
                    caption: 'Solar Pumpe',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'Solar_Pumpe_Status',
                },
                zirkulation_hk1: {
                    caption: 'HK1 Zirkulationspumpe',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'HK1-Zirkulationspumpe',
                },
                zirkulation_hk2: {
                    caption: 'HK2 Zirkulationspumpe',
                    fhem_device: 'vitoconnect',
                    fhem_reading: 'HK2-Zirkulationspumpe',
                },
                raum: {
                    caption: 'Raum',
                    fhem_device: 'MQTT2_pufferspeicher',
                    fhem_reading: 'TRaum',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeHeizung,
                },
                T1O: { caption: '', fhem_device: 'MQTT2_pufferspeicher', fhem_reading: 'T1O', unit: '°C', prec: 0, max_age: defaultMaxAgePuffer },
                T1U: { caption: '', fhem_device: 'MQTT2_pufferspeicher', fhem_reading: 'T1U', unit: '°C', prec: 0, max_age: defaultMaxAgePuffer },
                T2O: { caption: '', fhem_device: 'MQTT2_pufferspeicher', fhem_reading: 'T2O', unit: '°C', prec: 0, max_age: defaultMaxAgePuffer },
                T2U: { caption: '', fhem_device: 'MQTT2_pufferspeicher', fhem_reading: 'T2U', unit: '°C', prec: 0, max_age: defaultMaxAgePuffer },
                T3O: { caption: '', fhem_device: 'MQTT2_pufferspeicher', fhem_reading: 'T3O', unit: '°C', prec: 0, max_age: defaultMaxAgePuffer },
                T3U: { caption: '', fhem_device: 'MQTT2_pufferspeicher', fhem_reading: 'T3U', unit: '°C', prec: 0, max_age: defaultMaxAgePuffer },
            },
        },
        tagesschau: {
            name: 'Tagesschau',
            fhem_device: 'tagesschau',
            values: {
                url: { fhem_reading: 'url2' },
                zeit: { fhem_reading: 'datum' },
            },
        },
        bierzaehler: {
            name: 'Bier',
            fhem_device: 'MQTT2_bierzaehler',
            values: {
                fasswechsel: { caption: 'Fasswechsel', fhem_reading: 'reset', show_timestamp: true, hide_value: true },
                inhalt: { caption: 'Fass Inhalt', fhem_reading: 'inhalt', unit: 'Liter', prec: 1 },
                aenderung: { caption: 'Änderung', fhem_reading: 'inhalt', show_timestamp: true, hide_value: true },
                heute: { caption: 'Heute', fhem_reading: 'verbrauch_heute', unit: 'Liter', prec: 1 },
                bier_heute: { caption: '', fhem_reading: 'bier_heute' },
                gestern: { caption: 'Gestern', fhem_reading: 'verbrauch_gestern', unit: 'Liter', prec: 1 },
            },
        },
        gartenhuette: {
            name: 'Gartenhütte',
            values: {
                aussenlicht: {
                    caption: 'Aussenlicht',
                    fhem_device: 'MQTT2_lichtHaus',
                    fhem_reading: 'POWER',
                    fhem_typ: 'switch',
                    fhem_cmd: 'set MQTT2_lichtHaus $',
                },
                lueftung: {
                    caption: 'Lüftung',
                    fhem_device: 'MQTT2_GartenhuetteVentilator',
                    fhem_reading: 'POWER',
                    fhem_typ: 'switch',
                    fhem_cmd: 'set MQTT2_GartenhuetteVentilator $',
                },
                heizung: {
                    caption: 'Heizung',
                    fhem_device: 'MQTT2_gartenhuetteHeizung',
                    fhem_reading: 'manuell',
                    fhem_typ: 'switch',
                    fhem_cmd: 'setreading MQTT2_gartenhuetteHeizung manuell $',
                },
                pumpe: {
                    caption: 'Heizungspumpe',
                    fhem_device: 'MQTT2_gartenhuetteHeizung',
                    fhem_reading: 'POWER',
                },
                temperatur: {
                    caption: 'Temperatur',
                    fhem_device: 'MQTT2_gartenhuette',
                    fhem_reading: 'temperatur',
                    unit: '°C',
                    prec: 1,
                    max_age: defaultMaxAgeTemp,
                },
                ventil_luft: {
                    caption: 'Ventil Luft',
                    fhem_device: 'MQTT2_gartenWasserL',
                    fhem_reading: 'POWER',
                    fhem_typ: 'switch',
                    fhem_cmd: 'set MQTT2_gartenWasserL $',
                },
                ventil_wasser: {
                    caption: 'Ventil Wasser',
                    fhem_device: 'MQTT2_gartenWasserW',
                    fhem_reading: 'POWER',
                    fhem_typ: 'switch',
                    fhem_cmd: 'set MQTT2_gartenWasserW $',
                },
            },
        },
        // internet: {
        //     name: 'Internet',
        //     fhem_device: 'fritzbox',
        //     values: {
        //         todaySent: { fhem_reading: 'todaySent', prec: 2 },
        //         todayReceived: { fhem_reading: 'todayReceived', prec: 2 },
        //         sentYesterday: { fhem_reading: 'sentYesterday', prec: 1 },
        //         sentLastMonth: { fhem_reading: 'sentLastMonth', prec: 1 },
        //         receivedYesterday: { fhem_reading: 'receivedYesterday', prec: 1 },
        //         sentMonth: { fhem_reading: 'sentMonth', prec: 1 },
        //         receivedMonth: { fhem_reading: 'receivedMonth', prec: 1 },
        //         receivedLastMonth: { fhem_reading: 'receivedLastMonth', prec: 1 },
        //         box_rateUp: { fhem_reading: 'box_rateUp', prec: 1 },
        //         box_rateDown: { fhem_reading: 'box_rateDown', prec: 0 },
        //         box_wlanCount: { fhem_reading: 'box_wlanCount', prec: 0 },
        //     },
        // },
    },
};
