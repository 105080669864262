 <template>
  <section id="cover" class="min-vh-100">
    <div class="container form">
        <form class="justify-content-center form">
            <h3>Benutzer hinufügen</h3>
            <p v-if="msg">{{ msg }}</p>

            <div class="form-group">
                <input type="text" placeholder="Username" v-model="username" />
            </div>

            <div class="form-group">
                <input type="text" placeholder="eMail" v-model="email" />
            </div>

            <div class="form-group">
                <input type="password" placeholder="Passwort" v-model="password" />
            </div>

            <button @click="addUser"
                    class="btn btn-dark btn-lg">Hinzufügen</button>
      </form>
      </div>
    </section>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      msg: '',
    };
  },
  created() {
    if (!this.$store.getters.isAdmin) {
      this.$router.push('/login');
    }
  },
  methods: {
    async addUser() {
      try {
        const credentials = {
          username: this.username.trim(),
          email: this.email.trim(),
          password: this.password.trim(),
        };
        const response = await AuthService.signUp(credentials);
        const { message } = response;
        this.msg = message;
      } catch (error) {
        this.msg = error.response.data.message;
      }
    },
  },
};
</script>

<style scoped>

#cover {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    position: relative;
}

.login_form
{
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: -1;
    border-radius: 10px;
}

</style>
