import moment from 'moment';

function f2s(value, prec = 1) {
    if (!value) return '';
    if (value === '') return value;

    let f;

    if (typeof value === 'number') f = value;
    else f = parseFloat(value);

    const str = f.toFixed(prec);
    return str.replace(/[.]/, ',');
}

function showKwEUR(kw) {
    const strompreis = 0.32;

    let ret = `${f2s(kw, 2)} kW`;
    ret += ' (';
    ret += f2s(kw * strompreis, 2);
    ret += ' &euro;)';

    return ret;
}

function timestamp2s(sts, skipheute = true) {
    if (sts === '') return sts;

    moment.locale('de');
    const ts = moment(sts);
    const heute = moment();
    const gestern = moment().add(-1, 'days');
    const morgen = moment().add(1, 'days');

    if (ts.isSame(heute, 'day')) {
        if (skipheute) return `${ts.format('LT')} Uhr`;
        return `Heute ${ts.format('LT')} Uhr`;
    }
    if (ts.isSame(gestern, 'day')) return `Gestern ${ts.format('LT')} Uhr`;
    if (ts.isSame(morgen, 'day')) return `Morgen ${ts.format('LT')} Uhr`;
    return `${ts.format('L LT')} Uhr`;
}

function seconds2hhmm(seconds) {
    if (seconds >= 60 * 60 * 24) return `${Math.floor(seconds / 60 / 60)} Stunden`;

    return moment.utc(seconds * 1000).format('HH:mm');
}

export {
    f2s,
    showKwEUR,
    timestamp2s,
    seconds2hhmm,
};