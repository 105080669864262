/* eslint-disable no-console */
// import Axios from 'axios';
// import io from 'socket.io-client';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faPlay,
    faPause,
    faAngleDoubleDown,
    faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VuejsDialog from 'vuejs-dialog';
// import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
import firebase from 'firebase/app';
import 'firebase/messaging';
import Vue from 'vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import ToggleButton from 'vue-js-toggle-button';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import moment from 'moment';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import API from './api';
import { FhemSetStore, FhemConnect, FhemConnectSocket } from './FhemConnection';
import { deviceDefinition } from './config/deviceDefinition';

import Card from './components/Card.vue';
import CardWetter from './components/CardWetter.vue';

const firebaseConfig = {
    apiKey: 'AIzaSyBa_K8pkPoMnjzTikCyNQYHc287SfHC6UM',
    authDomain: 'hausuebersicht.firebaseapp.com',
    databaseURL: 'https://hausuebersicht.firebaseio.com',
    projectId: 'hausuebersicht',
    storageBucket: 'hausuebersicht.appspot.com',
    messagingSenderId: '660516449399',
    appId: '1:660516449399:web:d07cb64aab311cffd0aa72',
};

let firebaseApp = null;

try {
    firebaseApp = firebase.initializeApp(firebaseConfig);
    Vue.prototype.$messaging = firebase.messaging();
} catch (err) {
    console.log(err);
}

/*
navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
    Vue.prototype.$messaging.useServiceWorker(registration);
  }).catch((err) => {
    console.log(err);
  });
*/
library.add(faPlay, faPause, faAngleDoubleDown, faAngleDoubleUp);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ToggleButton);
Vue.use(VuejsDialog);

// Axios.defaults.headers.common.Authorization = `Bearer ${store.state.token}`;

// Vue.prototype.$socket = socket;
Vue.prototype.$http = API;
Vue.prototype.firebase = firebaseApp;
API.defaults.headers.common['x-access-token'] = store.state.accessToken;

FhemSetStore(store);
FhemConnect(deviceDefinition);

moment.locale('de');

if (store.getters.isLoggedIn) {
    FhemConnectSocket();
}

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('card', Card);
Vue.component('cardwetter', CardWetter);

new Vue({
    router,
    store,
    deviceDefinition,
    render: (h) => h(App),
    created() {
        // `this` points to the vm instance
        // this.$http.defaults.headers.common['x-access-token'] = store.state.accessToken;
    },
}).$mount('#app');

console.log('test');
