/* eslint-disable no-console */

// import axios from 'axios';
// import Vue from 'vue';
import io from 'socket.io-client';
import moment from 'moment';

const config = require('./config');
const util = require('./util');

let socket = io;
let store = null;
const mydeviceData = [];
let mydeviceDefinition;

function FhemConnectSocket() {
    socket = io.connect(`${config.WEBSOCKET_HOST}:${config.WEBSOCKET_PORT}`, {
        timeout: 5000,
        'sync disconnect on unload': true,
    });

    socket.on('connect', () => {
        console.log('conneced');

        socket.emit('authentication', store.state.accessToken);
    });

    socket.on('authenticated', () => {
        console.log('Authenticated');

        Object.keys(mydeviceDefinition).forEach((item) => {
            const d = mydeviceDefinition[item];
            Object.keys(d.values).forEach((valitem) => {
                const value = d.values[valitem];
                // console.log('GetReading: ', value.fhem_device, value.fhem_reading);
                //socket.emit('getReadingOnChange', { unit: value.fhem_device, reading: value.fhem_reading });
                //socket.emit('getReadingOnce', { unit: value.fhem_device, reading: value.fhem_reading });
                // Sendet Reading sofort und bei Änderung.
                socket.emit('getReading', { unit: value.fhem_device, reading: value.fhem_reading });
            });
        });
    });

    socket.on('device', (data) => {
        console.log(data);
    });

    socket.on('value', (data) => {
        console.log('value');
        // data.forEach((element) => console.log(element));
        console.log(data);
    });

    socket.on('requestError', (data) => {
        console.log('requestError');
        console.log(data);
    });

    socket.on('reading', (data) => {
        /*
        console.log('reading');
        console.log(data);
        */
        mydeviceData[data.unit][data.reading].val = data.value;
        mydeviceData[data.unit][data.reading].time = data.time;

        if (data.value.toUpperCase() === 'ON' || data.value === '1' || data.value.toUpperCase() === 'TRUE') {
            mydeviceData[data.unit][data.reading].val_bool = true;
        } else {
            mydeviceData[data.unit][data.reading].val_bool = false;
        }
    });

    socket.on('disconnect', () => {
        console.log('disconnected from server');
        Object.keys(mydeviceDefinition).forEach((item) => {
            const d = mydeviceDefinition[item];
            Object.keys(d.values).forEach((valitem) => {
                const value = d.values[valitem];
                value.val.val = '';
            });
        });
    });

    socket.open();
}

function FhemConnect(deviceDefinition) {
    let mid = 0;
    mydeviceDefinition = deviceDefinition;

    Object.keys(deviceDefinition).forEach((item) => {
        console.log(item); // key
        console.log(deviceDefinition[item]); // value
        const element = deviceDefinition[item];

        Object.keys(element.values).forEach((valitem) => {
            const value = element.values[valitem];

            if (!value.fhem_device) {
                value.fhem_device = element.fhem_device;
            }
            const fhemDevice = value.fhem_device;
            const fhemReading = value.fhem_reading;
            if (mydeviceData[fhemDevice] === undefined) {
                mydeviceData[fhemDevice] = [];
            }
            if (mydeviceData[fhemDevice][fhemReading] === undefined) {
                mydeviceData[fhemDevice][fhemReading] = {
                    val: '',
                    val_bool: false,
                    time: '',
                    id: mid,
                };
                mid += 1;
            }
            value.val = mydeviceData[fhemDevice][fhemReading];
        });
    });
}

function FhemCommand(command) {
    console.log('FHEM-Command:', command);
    socket.emit('commandNoResp', command);
    navigator.vibrate([25]);
}

const fhemmixin = {
    data() {
        return {
            devicedata: mydeviceDefinition,
        };
    },
    methods: {
        fhemCommand(command) {
            socket.emit('commandNoResp', command);
            navigator.vibrate([25]);
        },
        switchChanged(fhemCommand, value) {
            const cmd = fhemCommand.replace('$', value ? 'on' : 'off');
            FhemCommand(cmd);
        },
        isUpToDate(value) {
            if (value.max_age) {
                const time = moment(value.val.time);
                const now = moment();
                const age = now.diff(time);
                if (age > value.max_age * 1000) {
                    return false;
                }
            }
            return true;
        },
        getTimestamp(value) {
            let t = '';
            if (value.show_timestamp) {
                if (value.show_timestamp_prefix) {
                    t += ' ';
                    t += value.show_timestamp_prefix;
                    t += ' ';
                }
                t += util.timestamp2s(value.val.time);
            } else if (this.isUpToDate(value) === false) {
                t += ' (Wert von ';
                t += util.timestamp2s(value.val.time);
                t += ')';
            }
            return t;
        },
        filterData(value) {
            let t = '';
            if (!value.hide_value) {

                if (value.format === 'unixTimestamp') {
                    t = moment.unix(value.val.val).format('HH:mm');
                } else if (value.format === 'seconds') {
                    t = moment('2000-01-01').add(parseInt(value.val.val, 10), 'seconds').format('HH:mm');
                } else if (value.format === 'timestamp') {
                    t = util.timestamp2s(value.val.val);
                } else if (typeof value.prec !== 'undefined') {
                    t = util.f2s(value.val.val, value.prec);
                } else if (value.fhem_typ === 'window') {
                    if (value.val.val.toUpperCase() === 'CLOSED') {
                        t = '<svg class="ueb_svg_icon" data-txt="closed" version="1.0" xmlns="http://www.w3.org/2000/svg"  width="468pt" height="492pt" viewBox="0 0 468 492"  preserveAspectRatio="xMidYMid meet"> <metadata> Created by potrace 1.8, written by Peter Selinger 2001-2007 </metadata> <g transform="translate(0,492) scale(0.192593,-0.192593)"  stroke="none"> <path d="M73 2520 c-68 -41 -64 57 -61 -1241 3 -1145 3 -1175 22 -1200 11 -14 31 -34 45 -45 25 -19 52 -19 1138 -19 1106 0 1112 0 1140 21 15 11 38 40 51 64 l22 44 0 1143 c0 1259 3 1193 -62 1233 -33 20 -47 20 -1148 20 -1088 0 -1116 -1 -1147 -20z m2217 -765 l0 -415 -34 0 c-45 0 -86 -33 -86 -70 0 -41 34 -70 82 -70 l38 0 -2 -527 -3 -528 -1059 -3 c-774 -1 -1062 1 -1072 9 -12 10 -14 182 -14 1015 l0 1004 1075 0 1075 0 0 -415z"/> </g> </svg>';
                    }
                    if (value.val.val.toUpperCase() === 'OPEN') {
                        t = '<svg class=" ueb_svg_icon" data-txt="open" version="1.0" xmlns="http://www.w3.org/2000/svg" width="468pt" height="534pt" viewBox="0 0 468 534" preserveAspectRatio="xMidYMid meet"> <metadata> Created by potrace 1.8, written by Peter Selinger 2001-2007 </metadata> <g transform="translate(0,534) scale(0.192593,-0.192593)" stroke="none"> <path d="M74 2741 c-69 -42 -64 59 -64 -1237 l0 -1171 22 -33 c13 -18 39 -41 58 -51 26 -14 263 -49 915 -135 986 -130 928 -127 989 -51 25 31 31 49 34 102 l4 64 148 3 c170 3 188 10 228 88 l22 44 0 1143 c0 1259 3 1193 -62 1233 -33 20 -47 20 -1148 20 -1080 0 -1116 -1 -1146 -19z m946 -466 c443 -58 820 -108 838 -111 l32 -5 0 -434 0 -435 -40 0 c-105 0 -107 -123 -2 -137 l42 -6 0 -393 0 -394 -863 0 c-658 0 -866 3 -875 12 -9 9 -12 247 -12 1008 0 548 3 1000 7 1004 4 4 21 5 38 2 16 -3 392 -53 835 -111z m1268 -897 l-3 -1013 -127 -3 -128 -3 0 913 c0 893 -1 914 -20 946 -24 40 -70 69 -124 78 -23 4 -183 26 -356 49 l-315 43 538 1 537 1 -2 -1012z m-400 -1190 c-3 -42 -4 -43 -42 -46 -21 -2 -95 6 -165 17 -69 12 -196 30 -281 41 -85 12 -159 22 -165 25 -5 2 140 4 323 4 l333 1 -3 -42z"></path> </g> </svg>';
                    }
                } else if (value.format === 'icon') {
                    if (value.val.val.toUpperCase() === 'OFF' || value.val.val.toUpperCase() === 'FALSE') {
                        t = '<img src="img/power-button-off.svg" width="22" height="22"></img>';
                    } else if (value.val.val.toUpperCase() === 'ON' || value.val.val.toUpperCase() === 'TRUE') {
                        t = '<img src="img/power-button-on.svg" width="22" height="22"></img>';
                    }
                } else {
                    t = value.val.val;
                    if (t.toUpperCase() === 'OFF') {
                        t = 'Aus';
                    }
                    if (t.toUpperCase() === 'ON') {
                        t = 'An';
                    }
                    if (t.toUpperCase() === 'CLOSED') {
                        t = 'Geschlossen';
                    }
                    if (t.toUpperCase() === 'OPEN') {
                        t = 'Offen';
                    }
                }
                if (value.unit) {
                    t += ' ';
                    t += value.unit;
                }
            }
            t += this.getTimestamp(value);
            return t;
        },
    },
    created() {
        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login');
        }
    },
    computed: {
        hasWasserweg() {
            return this.$store.getters.getUser.roles.includes('ROLE_WASSERWEG');
        },
        hasMuehlengraben() {
            return this.$store.getters.getUser.roles.includes('ROLE_MUEHLENGRABEN');
        },
        hasWohnungBasti() {
            return this.$store.getters.getUser.roles.includes('ROLE_WOHNUNGBASTI');
        },
        isBasti() {
            return this.$store.getters.getUser.id == 2;
        },
    }
};

export default fhemmixin;

export function FhemSetStore(s) { store = s; }
export { FhemConnectSocket, FhemConnect, FhemCommand };
