<template>
  <div>
    <div class="uebersicht container">
      <div>
        <div class="row">
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <cardcameraAM
              :camname="'Haustür'"
              :id="'48eb65b0-99da-415a-86e9-138bae766e84'"
              :startLive="true"
            ></cardcameraAM>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <cardcameraAM
              :camname="'Grundstück'"
              :id="'08def68b-5184-4e98-9945-9d187353362b'"
              :startLive="true"
            ></cardcameraAM>
          </div>
          <div class="col-md-12 col-xs-12" v-if="hasMuehlengraben">
            <tueroeffnerAM v-if="hasMuehlengraben"></tueroeffnerAM>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fhemmixin from '../FhemConnection';
import CardCameraAM from '../components/CardCameraAM.vue';
import TueroeffnerAM from '../components/TueroeffnerAM.vue';

export default {
  components: {
    cardcameraAM: CardCameraAM,
    tueroeffnerAM: TueroeffnerAM,
  },
  data() {
    return {};
  },
  computed: {},
  mixins: [fhemmixin],
};
</script>
