<template>
  <div class="widget">
    <div>
      <h2 @click="toggleVisible">
        Essensplan

        <font-awesome-icon
          v-if="!visibleAdd"
          icon="angle-double-down"
          class="iconcolapse"
        />
        <font-awesome-icon v-if="visibleAdd" icon="angle-double-up" class="iconcolapse" />
      </h2>

      <div v-if="visibleAdd" class="container">
        <h3>Hinzuf&uuml;gen</h3>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="description">Gericht</label>
            <input name="description" class="form-control" v-model="formFields.name" />
          </div>
          <div class="form-group">
            <label for="description">Beschreibung</label>
            <textarea
              name="description"
              class="form-control"
              v-model="formFields.beschreibung"
            ></textarea>
          </div>

          <div class="form-group">
            <label for="picture">Bild</label>
            <input
              type="file"
              name="picture"
              class="form-control-file"
              id="picture"
              @change="onFileChange"
            />
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-dark btn-sm" value="Hinzufügen" />
          </div>
        </form>
      </div>
      <div>
        <b-table
          hover
          :items="items"
          :fields="fields"
          :sort-by="'tage'"
          id="abfall-table"
        >
          <template #cell(name)="row">
            <div
              class="sbcolor-box sbfloating-box abfall-icon"
              v-bind:style="{ backgroundColor: row.item.color }"
            ></div>
            <span>{{ row.item.name }}</span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import fhemmixin from '../FhemConnection';

export default {
  name: 'CardEssensplan',
  props: ['device'],
  mixins: [fhemmixin],
  data() {
    return {
      items: [],
      visibleAdd: false,
      formFields: {
        name: null,
        beschreibung: null,
        picture: null,
      },
      fields: [
        { key: 'name', label: 'Gericht' },
        { key: 'tage' },
        { key: 'wochentag', label: 'Tag' },
        { key: 'datum' },
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    toggleVisible() {
      this.visibleAdd = !this.visibleAdd;
      if (this.visibleAdd) {
        //this.loadChart();
      }
    },
    onFileChange(event) {
      this.formFields.picture = event.target.files[0];
    },
    submitForm() {
      // let formData = new FormData();
      // formData.append('name', this.formFields.name);
      // formData.append('beschreibung', this.formFields.beschreibung);
      // formData.append('file', this.formFields.picture);

      console.log(this.formFields);
      this.$http
        .post(
          '/api/addessensplan',
          {
            name: this.formFields.name,
            beschreibung: this.formFields.beschreibung,
            file: this.formFields.picture,
          }
          // {
          //   headers: {
          //     'Content-Type': 'multipart/form-data',
          //   },
          // }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.$http.get('/api/getessensplan').then((response) => {
      this.items = response.data;
    });
  },
};
</script>

<style>
.abfall-icon {
  border-radius: 10px;
}

.sbfloating-box {
  float: left;
}

.sbcolor-box {
  width: 20px;
  height: 20px;
  vertical-align: baseline;
  margin-right: 10px;
}
</style>
