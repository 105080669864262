<template>
  <div>
    <div class="uebersicht container">
      <div>
        <div class="row">
          <div class="col-md-6 col-xs-12" v-if="hasWasserweg">
            <card v-bind:device="devicedata.trockner"></card>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasWasserweg">
            <card v-bind:device="devicedata.waschmaschine"></card>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasWohnungBasti">
            <card v-bind:device="devicedata.waschmaschine_ela"></card>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasWohnungBasti">
            <div>
              <button @click="setWaescheUser" class="btn btn-dark btn-lg btn-block">
                Ela &amp; Basti bei Conny
              </button>
            </div>
            <p>&nbsp;</p>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <card v-bind:device="devicedata.trockner_conny"></card>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <card v-bind:device="devicedata.waschmaschine_conny"></card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fhemmixin from '../FhemConnection';

export default {
  mixins: [fhemmixin],
  methods: {
    setWaescheUser() {
      this.fhemCommand('setreading ButtonWaschmaschineBastiEla state 1');
    },
  },
};
</script>
