<template>
  <span class="temperature-button">
    <b-dropdown
      id="dropdown-left"
      size="sm"
      :text="desiredTemperatureString"
      variant="primary"
      class="m-2 fhemdropdown"
    >
      <b-dropdown-item @click="setTemperature('off')">Aus</b-dropdown-item>
      <b-dropdown-item @click="setTemperature('18.0')">18 °C</b-dropdown-item>
      <b-dropdown-item @click="setTemperature('19.0')">19 °C</b-dropdown-item>
      <b-dropdown-item @click="setTemperature('20.0')">20 °C</b-dropdown-item>
      <b-dropdown-item @click="setTemperature('21.0')">21 °C</b-dropdown-item>
      <b-dropdown-item @click="setTemperature('22.0')">22 °C</b-dropdown-item>
      <b-dropdown-item @click="setTemperature('23.0')">23 °C</b-dropdown-item>
      <b-dropdown-item @click="setTemperature('24.0')">24 °C</b-dropdown-item>
    </b-dropdown>
  </span>
</template>

<script>
import { FhemCommand } from '../FhemConnection';

export default {
  name: 'TemperatureButton',
  props: {
    desiredTemperature: String,
    fhemCommand: String,
  },
  computed: {
    desiredTemperatureString() {
      const r = this.desiredTemperature.toString();
      return r;
    },
  },
  mixins: [],
  data() {
    return {};
  },
  components: {},
  methods: {
    setTemperature(value) {
      const cmd = this.fhemCommand.replace('$', value);
      FhemCommand(cmd);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
