<template>
  <div>
    <div class="uebersicht container">
      <div>
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <card v-bind:device="devicedata.aussen_ww3" v-if="hasWasserweg"></card>
            <card v-bind:device="devicedata.aussen_am2" v-if="hasMuehlengraben"></card>

            <tueroeffner v-if="hasWasserweg || isBasti"></tueroeffner>
            <tueroeffnerAM v-if="hasMuehlengraben"></tueroeffnerAM>
          </div>
          <div class="col-md-6 col-xs-12">
            <abfallkalender v-bind:device="devicedata.abfallkalender"></abfallkalender>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12" v-if="hasWasserweg">
            <cardcamera :camname="'Haustür'" :id="'2'"></cardcamera>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasWasserweg">
            <cardcamera :camname="'Carport'" :id="'3'"></cardcamera>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <cardcameraAM
              :camname="'Haustür'"
              :id="'48eb65b0-99da-415a-86e9-138bae766e84'"
            ></cardcameraAM>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <cardcameraAM
              :camname="'Grundstück'"
              :id="'08def68b-5184-4e98-9945-9d187353362b'"
            ></cardcameraAM>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <card v-bind:device="devicedata.stromverbrauch_akt"></card>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <card v-bind:device="devicedata.stromverbrauch"></card>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <card v-bind:device="devicedata.stromverbrauch_stat"></card>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasWasserweg">
            <card v-bind:device="devicedata.stromverbrauchWW"></card>
          </div>
          <div class="col-md-6 col-xs-12" v-if="hasWasserweg">
            <card v-bind:device="devicedata.stromverbrauchWWEinspeisung"></card>
          </div>          

          <!--<div class="col-md-6 col-xs-12">
            <card-putzplan></card-putzplan>
          </div>-->
          <div class="col-md-6 col-xs-12" v-if="hasMuehlengraben">
            <card-internet v-bind:device="devicedata.internet"></card-internet>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fhemmixin from '../FhemConnection';
import CardCamera from '../components/CardCamera.vue';
import CardCameraAM from '../components/CardCameraAM.vue';
import Tueroeffner from '../components/Tueroeffner.vue';
import TueroeffnerAM from '../components/TueroeffnerAM.vue';
import CardAbfallkalender from '../components/CardAbfallkalender.vue';
// import CardPutzplan from '../components/CardPutzplan.vue';
import CardInternet from '../components/CardInternet.vue';

export default {
  components: {
    cardcamera: CardCamera,
    cardcameraAM: CardCameraAM,
    tueroeffner: Tueroeffner,
    tueroeffnerAM: TueroeffnerAM,
    abfallkalender: CardAbfallkalender,
    // 'card-putzplan': CardPutzplan,
    'card-internet': CardInternet,
  },
  data() {
    return {};
  },
  computed: {},
  mixins: [fhemmixin],
};
</script>
