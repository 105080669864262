<template>
  <div class="widget">
    <div>
      <h2>{{ device.name }}</h2>
      <table class="table table-sm">
        <tbody>
          <tr>
            <td>{{ device.values.status.caption }}</td>
            <td v-html="filterData(device.values.status)"></td>
          </tr>
          <tr>
            <td>{{ device.values.abgas.caption }}</td>
            <td>{{ filterData(device.values.abgas) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.temperatur.caption }}</td>
            <td>{{ filterData(device.values.temperatur) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.warmwasserist.caption }}</td>
            <td>
              {{ filterData(device.values.warmwasserist) }} (Soll:
              {{ filterData(device.values.warmwassersoll) }})
            </td>
          </tr>
          <tr>
            <td>{{ device.values.vorlauf1.caption }}</td>
            <td>
              HK1: {{ filterData(device.values.vorlauf1) }} / HK2:
              {{ filterData(device.values.vorlauf2) }}
            </td>
          </tr>

          <tr>
            <td>{{ device.values.zirkulation_hk1.caption }}</td>
            <td>{{ filterData(device.values.zirkulation_hk1) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.zirkulation_hk2.caption }}</td>
            <td>{{ filterData(device.values.zirkulation_hk2) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.aussentemperatur.caption }}</td>
            <td>{{ filterData(device.values.aussentemperatur) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.solartemperatur.caption }}</td>
            <td>{{ filterData(device.values.solartemperatur) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.solar_pumpe.caption }}</td>
            <td>{{ filterData(device.values.solar_pumpe) }}</td>
          </tr>
          <tr>
            <td>{{ device.values.raum.caption }}</td>
            <td>{{ filterData(device.values.raum) }}</td>
          </tr>
          <tr>
            <td colspan="2" id="pp">
              <div><b>Pufferspeicher</b></div>

              <div class="contenedor">
                <div class="sbfloating-box">
                  <div class="column-in-center gaugeMeter">
                    <VueSvgGauge
                      class="heizung-gauge"
                      :start-angle="-110"
                      :end-angle="110"
                      :value="puffer1percent"
                      :separator-step="0"
                      :min="0"
                      :max="100"
                      :gauge-color="getGaugeColor(puffer1percent)"
                      :scale-interval="0"
                    >
                      <div class="gauge-inner-text">
                        <span>{{ puffer1 }}</span>
                      </div>
                    </VueSvgGauge>
                    <div class="puffer_detail">{{ filterData(device.values.T1O) }}</div>
                    <div class="puffer_detail">{{ filterData(device.values.T1U) }}</div>
                  </div>
                </div>
                <div class="sbfloating-box">
                  <div class="column-in-center gaugeMeter">
                    <VueSvgGauge
                      class="heizung-gauge"
                      :start-angle="-110"
                      :end-angle="110"
                      :value="puffer2percent"
                      :separator-step="0"
                      :min="0"
                      :max="100"
                      :gauge-color="getGaugeColor(puffer2percent)"
                      :scale-interval="0"
                    >
                      <div class="gauge-inner-text">
                        <span>{{ puffer2 }}</span>
                      </div>
                    </VueSvgGauge>
                    <div class="puffer_detail">{{ filterData(device.values.T2O) }}</div>
                    <div class="puffer_detail">{{ filterData(device.values.T2U) }}</div>
                  </div>
                </div>
                <div class="sbfloating-box">
                  <div class="column-in-center gaugeMeter">
                    <VueSvgGauge
                      class="heizung-gauge"
                      :start-angle="-110"
                      :end-angle="110"
                      :value="puffer3percent"
                      :separator-step="0"
                      :min="0"
                      :max="100"
                      :gauge-color="getGaugeColor(puffer3percent)"
                      :scale-interval="0"
                    >
                      <div class="gauge-inner-text">
                        <span>{{ puffer3 }}</span>
                      </div>
                    </VueSvgGauge>
                    <div class="puffer_detail">{{ filterData(device.values.T3O) }}</div>
                    <div class="puffer_detail">{{ filterData(device.values.T3U) }}</div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
import { VueSvgGauge } from 'vue-svg-gauge';
import fhemmixin from '../FhemConnection';

const util = require('../util');

function pufferPercent(temp) {
  if (!temp) {
    return 0;
  }

  const min = 35;
  const max = 75;
  let perc = (temp - min) / (max - min);
  perc *= 100;
  if (perc < 0) perc = 0;
  if (perc > 100) perc = 100;

  return perc;
}

export default {
  name: 'CardHeizung',
  props: ['device'],
  mixins: [fhemmixin],
  components: {
    VueSvgGauge,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    puffer1: function puf1() {
      if (
        !this.isUpToDate(this.device.values.T1O) ||
        !this.isUpToDate(this.device.values.T1U)
      )
        return 0;
      return util.f2s(
        (parseFloat(this.device.values.T1O.val.val) +
          parseFloat(this.device.values.T1U.val.val)) /
          2,
        0
      );
    },
    puffer1percent: function puf1p() {
      if (
        !this.isUpToDate(this.device.values.T1O) ||
        !this.isUpToDate(this.device.values.T1U)
      )
        return 0;
      return pufferPercent(
        (parseFloat(this.device.values.T1O.val.val) +
          parseFloat(this.device.values.T1U.val.val)) /
          2
      );
    },
    puffer2: function puf2p() {
      if (
        !this.isUpToDate(this.device.values.T2O) ||
        !this.isUpToDate(this.device.values.T2U)
      )
        return 0;
      return util.f2s(
        (parseFloat(this.device.values.T2O.val.val) +
          parseFloat(this.device.values.T2U.val.val)) /
          2,
        0
      );
    },
    puffer2percent: function puf12p() {
      if (
        !this.isUpToDate(this.device.values.T2O) ||
        !this.isUpToDate(this.device.values.T2U)
      )
        return 0;
      return pufferPercent(
        (parseFloat(this.device.values.T2O.val.val) +
          parseFloat(this.device.values.T2U.val.val)) /
          2
      );
    },
    puffer3: function puf3() {
      if (
        !this.isUpToDate(this.device.values.T3O) ||
        !this.isUpToDate(this.device.values.T3U)
      )
        return 0;
      return util.f2s(
        (parseFloat(this.device.values.T3O.val.val) +
          parseFloat(this.device.values.T3U.val.val)) /
          2,
        0
      );
    },
    puffer3percent: function puf3p() {
      if (
        this.isUpToDate(this.device.values.T3O) === false ||
        this.isUpToDate(this.device.values.T3U) === false
      )
        return 0;
      return pufferPercent(
        (parseFloat(this.device.values.T3O.val.val) +
          parseFloat(this.device.values.T3U.val.val)) /
          2
      );
    },
  },
  methods: {
    getGaugeColor(e) {
      if (e > 80) return '#e32100';
      if (e > 60) return '#ff8700';
      if (e > 40) return '#ffd900';
      return '#0802d1';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  text-align: left;
}

.contenedor {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.gaugeMeter {
  justify-content: space-around;
}

.heizung-gauge {
  margin: 0px;
  max-width: 90px;
  float: left;
}

.sbfloating-box {
  float: left;
}

.puffer_detail {
  text-align: center;
}

.gauge-inner-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 30px;
}

.gauge-inner-text > span {
  max-width: 100px;
  color: white;
}
</style>
