<template>
  <div id="app">
    <div class="main">
      <router-view />
      <div v-if="isLoggedIn && path === '/'">
        <a href="#" @click="logout()">Logout</a>
      </div>
    </div>

    <div v-if="isLoggedIn && path !== '/opendoor' && path !== '/opendooram'" class="sbonavbar">
      <router-link to="/">
        <img src="img/haus.svg" alt="Haus" class="img-icon" />
      </router-link>

      <router-link v-if="hasWohnungBasti" to="/wohnung">
        <img src="img/livingroom.svg" alt="Wohnung" class="img-icon" />
      </router-link>

      <router-link v-if="hasWohnungBasti && false" to="/essensplan">
        <!-- TODO -->
        <img src="img/essen.svg" alt="Essensplan" class="img-icon" />
      </router-link>

      <router-link to="/heizung">
        <img src="img/heizung.svg" alt="Heizung" class="img-icon" />
      </router-link>

      <router-link to="/nachrichten">
        <img src="img/nachrichten.svg" alt="Nachrichten" class="img-icon" />
      </router-link>

      <router-link to="/waesche">
        <img src="img/waesche.svg" alt="Wäsche" class="img-icon" />
      </router-link>

      <router-link to="/gartenhuette" v-if="hasWasserweg || hasWohnungBasti">
        <img src="img/bier.svg" alt="Gartenhütte" class="img-icon" />
      </router-link>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/messaging';

const config = require('./config');

export default {
  async created() {
    if (this.$store.getters.isLoggedIn) {
      this.$messaging
        .requestPermission()
        .then(() => firebase.messaging().getToken())
        .then((mtoken) => {
          console.log('Token: ', mtoken); // Receiver Token to use in the notification
          try {
            const credentials = {
              id: this.$store.getters.getUser.id,
              token: mtoken,
            };
            this.$http.post(`${config.BACKEND_URL}api/auth/settoken/`, credentials);
          } catch (error) {
            this.msg = error.response.data.message;
          }
        })
        .catch((err) => {
          console.log('Unable to get permission to notify.', err);
        });

      this.$messaging.onMessage((payload) => {
        console.log('Message received!!!!', payload);
        // ...
      });
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    hasWasserweg() {
      return this.$store.getters.getUser.roles.includes('ROLE_WASSERWEG');
    },
    hasMuehlengraben() {
      return this.$store.getters.getUser.roles.includes('ROLE_MUEHLENGRABEN');
    },
    hasWohnungBasti() {
      return this.$store.getters.getUser.roles.includes('ROLE_WOHNUNGBASTI');
    },
    isBasti() {
      return this.$store.getters.getUser.id == 2;
    },
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style>
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~bootstrap/dist/css/bootstrap.min.css';

html,
body {
  width: 100%;
}

body {
  overflow-x: hidden;
  color: rgba(237, 241, 252, 0.6);
  background: rgb(237, 241, 252);
  background: linear-gradient(
    137deg,
    rgba(237, 241, 252, 1) 0%,
    rgba(241, 240, 249, 1) 42%,
    rgba(237, 241, 252, 1) 100%
  );
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

#app {
  text-align: center;
  color: rgb(237, 241, 252);
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .sbonavbar {
    overflow: hidden;
    background-color: #3d4755;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: white;
  }
  .main {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 600px) {
  .sbonavbar {
    overflow: hidden;
    background-color: #3d4755;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    border-bottom-style: solid;
    border-bottom-width: 0.1px;
    border-bottom-color: white;
  }

  .main {
    margin-top: 100px;
  }
}

.sbonavbar a {
  display: inline-block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
}

.btn,
.btn-primary,
.pagination,
.page-item,
.page-link {
  background-color: #3d4755;
  border: 20px;
  background-attachment: fixed;
  background-size: cover;
  color: rgb(244, 244, 245);
}

.page-item.active .page-link {
  background-color: #65768d;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #323b47;
}

.primary {
  background-color: #3d4755;
}

.dropdownmenu .dropdown-menu {
  background-color: #3d4755;
}
.dropdownmenu .dropdown-menu .dropdown-item {
  background-color: #3d4755;
  color: #ffffff;
}
.dropdownmenu > button {
  background-color: #3d4755;
  width: 100px;
  height: 28px;
}

.btn-primary.dropdown-toggle {
  background-color: #3d4755;
}

.navbar-toggler {
  color: rgba(244, 244, 245, 0.6);
}

.page-link {
  background-color: #3d4755;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
div,
.table,
tr,
td {
  color: #ffffff;
}

a:link,
a:visited,
a:hover,
a:active {
  color: #3d4755;
}

.dark {
  color: #3d4755;
}

.img-icon {
  width: 52px;
  height: 52px;
  margin: 4px;
  padding: 4px;
  filter: invert(1);
  border-style: solid;
  border-width: 0.1px;
  border-color: black; /* Wird weiss, wegen filter invert */
  border-radius: 3px;
}

table {
  text-align: left;
}

.widget {
  display: block;
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  padding: 0.3rem 0.3rem;
  background: #546986;
  border-radius: 10px;
  -webkit-box-shadow: 0 23px 20px -20px rgba(9, 10, 18, 0.1),
    0 0 15px rgba(9, 10, 18, 0.06);
  box-shadow: 0 23px 20px -20px rgba(9, 10, 18, 0.1), 0 0 15px rgba(9, 10, 18, 0.06);
}

.ueb_svg_icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
}
.ueb_svg_icon text.title {
  text-transform: none !important;
  letter-spacing: 2px !important;
}
.ueb_svg_icon:not([fill]):not(.jssvg) {
  fill: rgba(244, 244, 245, 0.6);
}

.dg-content {
  color: black;
}
.dg-btn--ok {
  border-color: red;
  color: red;
}

.dg-btn--cancel {
  background-color: dimgray;
}
</style>
