<template>
  <div class="widget">
    <div>
      <div>
        <button @click="oeffneTuer" class="btn btn-dark btn-lg">
          Haustür Mühlengraben öffnen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { FhemCommand } from '../FhemConnection';

export default {
  name: 'TueroeffnerAM',
  props: {},
  mixins: [],
  data() {
    return {};
  },
  components: {},
  methods: {
    oeffneTuer() {
      const options = {
        okText: 'Öffnen',
        cancelText: 'Abbrechen',
        backdropClose: true,
      };
      this.$dialog.confirm('Haustür wirklich öffnen?', options).then(() => {
        FhemCommand('set MQTT2_haustuerAM on-for-timer 3');
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
